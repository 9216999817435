import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import TimeIntervalInput from "./TimeIntervalInput";

import { StationState } from "../../stations/constants/StationState";
import { JsxElement } from "typescript";
import StateSelector from "./StateSelector";
import StationSelector from "./StationSelector";

interface Props {
  item: ScheduleItem;
  handleSubmit: (item: ScheduleItem) => void;
  submitButtonColor: any;
  submitButtonVariant: any;
  submitButtonLabel: any;
  stations: Station[];
}

export default function IntervalSelector(props: Props) {
  const [scheduleItem, setScheduleItem] = React.useState<ScheduleItem>(
    props.item
  );

  const onTimeIntervalSelection = (
    selectedStart: Date,
    selectedEnd: Date
  ): void => {
    setScheduleItem({
      ...scheduleItem,
      ...{ start: selectedStart, end: selectedEnd },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
        <StateSelector
          defaultSelected={props.item.state}
          handleSelect={(selected: StationState) => {
            setScheduleItem({
              ...scheduleItem,
              ...{ state: selected },
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
        <StationSelector
          stations={props.stations}
        defaultSelected={""}
        handleSelect={(selectedId: string) => {
          setScheduleItem({
            ...scheduleItem,
            ...{ stationId: selectedId },
          });
        }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <TimeIntervalInput
          defaultStart={props.item.start}
          defaultEnd={props.item.end}
          handleIntervalSelection={onTimeIntervalSelection}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Button
          color={props.submitButtonColor}
          variant={props.submitButtonVariant}
          sx={{ width: "100%", height: "100%" }}
          onClick={() => props.handleSubmit(scheduleItem)}
        >
          {props.submitButtonLabel}
        </Button>
      </Grid>
    </Grid>
  );
}
