export function convertToBarStackDataType<T>(
  dataRecords: T[],
  barStackConversionParams: BarStackConversionParamType<T>
): BarStackDataType[] {
  if (!(dataRecords && barStackConversionParams)) return [];

  let mapObj: any = {};

  let YAxisFieldName_1_keys: Set<string> = new Set<string>(
    dataRecords.map((record: T) => {
      return String(record[barStackConversionParams.YAxisFieldName_1]);
    })
  );

  let YAxisFieldName_2_keys: Set<string> = new Set<string>(
    dataRecords.map((record: T) => {
      return String(record[barStackConversionParams.YAxisFieldName_2]);
    })
  );

  console.log("YAxisFieldName_1_keys");
  console.log(YAxisFieldName_1_keys);
  console.log("YAxisFieldName_2_keys");
  console.log(YAxisFieldName_2_keys);

  dataRecords.forEach((record: T) => {
    if (!mapObj[record[barStackConversionParams.YAxisFieldName_1]]) {
      YAxisFieldName_1_keys.forEach(() => {
        mapObj[record[barStackConversionParams.YAxisFieldName_1]] = {
          YAxisFieldName_1_key: "N/A",
        };
      });
    }
    mapObj[record[barStackConversionParams.YAxisFieldName_1]][
      record[barStackConversionParams.YAxisFieldName_2]
    ] = record[barStackConversionParams.XAxisFieldName];
  });

  const barStackData: BarStackDataType[] = Object.entries(mapObj).map(
    ([key, value]: [any, any]) => {
      let mapObj2: any = {};
      mapObj2["YAxisFieldName_1"] = key;
      YAxisFieldName_2_keys.forEach((existingStationName: string) => {
        mapObj2[existingStationName] = value[existingStationName]
          ? value[existingStationName]
          : 0;
      });
      return mapObj2;
    }
  );
  console.log("barStackData");
  console.log([...barStackData]);
  return barStackData;
}
