import * as React from "react";

import {
  Button,
  Container,
  Fab,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { StationState } from "../stations/constants/StationState";
import IntervalSelector from "./util-components/IntervalSelector";
import ScheduledInterval from "./util-components/ScheduledInterval";
import theme from "../app/theme/AppTheme";

const main = {
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
};

/***************************************************************************/

export default function StationsScheduler(props: any) {
  const [scheduleQueue, setScheduleQueue] = React.useState<ScheduleItem[]>([]);

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [showNewIntervalForm, setShowNewIntervalForm] =
    React.useState<boolean>(false);

  const handleNewItem = (newItem: ScheduleItem) => {
    setScheduleQueue((prev) =>
      [...prev, { ...newItem, id: prev.length }].sort(
        (a, b) => a.start - b.start
      )
    );
  };

  const handleModifiedItem = (modifiedItem: ScheduleItem) => {
    setScheduleQueue((prev) =>
      prev
        .map((item) => (item.id === modifiedItem.id ? modifiedItem : item))
        .sort((a, b) => a.start - b.start)
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        ...main,
        width: "100%",
      }}
    >
      <Stack
        spacing={3}
        mt={0}
        mb={5}
        ml={5}
        mr={5}
        pt={8}
        sx={{
          ...main,
          width: isSmUp ? "80vw" : "100%",
        }}
      >
        {showNewIntervalForm ? (
          <React.Fragment>
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => setShowNewIntervalForm(false)}
            >
              <RemoveIcon />
            </Fab>
            <IntervalSelector
              stations={props.stations}
              item={{
                id: -1,
                start: new Date(),
                end: new Date(),
                state: StationState.offline,
                stationId: "",
              }}
              handleSubmit={handleNewItem}
              submitButtonColor="success"
              submitButtonVariant="outlined"
              submitButtonLabel="Új"
            />
          </React.Fragment>
        ) : (
          <Fab
            color="secondary"
            aria-label="add"
            onClick={() => setShowNewIntervalForm(true)}
          >
            <AddIcon />
          </Fab>
        )}

        <Stack spacing={2}>
          {scheduleQueue.length > 0 ? (
            scheduleQueue.map((item) => (
              <ScheduledInterval
                stations={props.stations}
                item={item}
                handleSubmit={handleModifiedItem}
                submitButtonColor="info"
                submitButtonVariant="outlined"
                submitButtonLabel="Módosítás"
              />
            ))
          ) : (
            <Typography
              color={theme.palette.secondary.main}
              variant="subtitle1"
            >
              Jelenleg nincs betervezett időtartam.
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
