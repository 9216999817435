import React, { Context, ReactElement } from "react";
import { JsxElement } from "typescript";

const FirebaseDataContext: Context<any> = React.createContext(null);

export const withFirebaseDataContext = (
  MyComponent: (props: any) => JSX.Element,
  props: any
) => (
  <FirebaseDataContext.Consumer>
    {({
      firebase,
      stations,
      transactions,
      chargeTokens,
      userInfo,
      users,
      profiles,
      handleNewUser,
      handleModifiedUser,
      handleDeletedUser,
      handleNewProfile,
      handleModifiedProfile,
      handleDeletedProfile,
      handleUpdateUsers,
      handleNewToken,
      handleModifiedToken,
      handleDeletedToken,
      isSmUp,
      setRefresh
    }) => (
      <MyComponent
        {...props}
        firebase={firebase}
        stations={stations}
        transactions={transactions}
        chargeTokens={chargeTokens}
        userInfo={userInfo}
        users={users}
        profiles={profiles}
        handleNewUser={handleNewUser}
        handleModifiedUser={handleModifiedUser}
        handleDeletedUser={handleDeletedUser}
        handleNewProfile={handleNewProfile}
        handleModifiedProfile={handleModifiedProfile}
        handleDeletedProfile={handleDeletedProfile}
        handleUpdateUsers={handleUpdateUsers}
        handleNewToken={handleNewToken}
        handleModifiedToken={handleModifiedToken}
        handleDeletedToken={handleDeletedToken}
        isSmUp={isSmUp}
        refresh={setRefresh}
      />
    )}
  </FirebaseDataContext.Consumer>
);

export default FirebaseDataContext;
