import React, { useState } from "react";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { GradientPinkBlue, GradientSteelPurple } from "@visx/gradient";
import letterFrequency, { LetterFrequency } from "./letterFrequency";
import { animated, useTransition, to } from "react-spring";
import { StationsToStatesMapType } from "../../overview/OverViewScreen";
import { StationState } from "../../stations/constants/StationState";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme/AppTheme";
import { Typography } from "@mui/material";

const typography = {
  color: theme.palette.secondary.contrastText,
  secondaryColor: theme.palette.secondary.dark,
};

// data and types
interface PieChartValueType {
  label: string;
  value: number;
}

let ownersOuter: string[] = [];

const defaultMargin = { top: 100, right: 50, bottom: 50, left: 50 };

export type PieProps = {
  width: number;
  height: number;
  stations: Station[];
  stationDataByOwner: BarStackDataType[];
  stationDataByHub: BarStackDataType[];
  stationDataById: BarStackDataType[];
  stationToStatesMap: StationsToStatesMapType;
  margin?: typeof defaultMargin;
  animate?: boolean;
  chartLabel: string;
};

export default function Example({
  width,
  height,
  stations,
  stationDataByOwner,
  stationDataByHub,
  stationDataById,
  stationToStatesMap,
  chartLabel,
  margin = defaultMargin,
  animate = true,
}: PieProps) {
  const [selectedOwner, setSelectedOwner] = useState<string | null>(null);
  const [selectedHub, setSelectedHub] = useState<string | null>(null);
  const [selectedAlphabetLetter, setSelectedAlphabetLetter] = useState<
    string | null
  >(null);

  const navigate = useNavigate();
  const location = useLocation();
  /*
  const hubMap = () => {
    type myObjType = {
      [k: string]: any | null;
    };
    let myObj: myObjType={};
    stations.forEach((data) => {
      if (!myObj[data.YAxisFieldName_1]) {
        myObj[data.YAxisFieldName_1] = Object();
      }
      myObj[data.YAxisFieldName_1]].push(station);
    });
  }
*/

  /*let maxValueObj = ()=> {
      type myObjType = {
        [k: string]: any | null;
      };
      let myObj: myObjType={};
      data.forEach((data) => {
        if (!myObj[data.YAxisFieldName_1]) {
          myObj[data.YAxisFieldName_1] = Object();
        }
        myObj[data.YAxisFieldName_1]].push(station);
      });
    }*/

  /**OWNERS */

  const ownerNames = Object.keys(stationDataByOwner[0]).filter(
    (k) => k !== "YAxisFieldName_1"
  ) as any[];
  const [owners, setOwners] = React.useState<PieChartValueType[]>(
    ownerNames.map((name) => ({
      label: name,
      value: stations.filter((station) => station.owner === name)
        .length /*Math.log(
        Number(
          stationDataByOwner.reduce(
            (previousValue, currentValue) => previousValue + currentValue[name],
            1
          )
        )
      )*/,
    }))
  );
  const [ownersDisplayed, setOwnersDisplayed] =
    React.useState<PieChartValueType[]>(owners);
  console.log("ownersDisplayed");
  console.log([...ownersDisplayed]);

  ownersOuter = ownerNames;

  /**HUBS */

  let hubNames = Object.keys(stationDataByHub[0]).filter(
    (k) => k !== "YAxisFieldName_1"
  ) as any[];
  const [hubs, setHubs] = React.useState<PieChartValueType[]>(
    Array.from(
      new Set(
        hubNames.map((name) => ({
          label: name,
          value: 1 /*Math.log(
        Number(
          stationDataByHub.reduce(
            (previousValue, currentValue) => previousValue + currentValue[name],
            1
          )
        )
      )*/,
        }))
      )
    )
  );
  const [hubsDisplayed, setHubsDisplayed] =
    React.useState<PieChartValueType[]>(hubs);

  /**STATIONSData */

  let stationNames = Object.keys(stationDataById[0]).filter(
    (k) => k !== "YAxisFieldName_1"
  ) as any[];
  const [stationsData, setStationsDataData] = React.useState<
    PieChartValueType[]
  >(
    stationNames.map((name) => ({
      label: name,
      value: 1 /*Math.log(
        Number(
          stationDataById.reduce(
            (previousValue, currentValue) => previousValue + currentValue[name],
            1
          )
        )
      )*/,
    }))
  );

  console.log("stationToStatesMap", stationToStatesMap);

  const [stationStateNames, setStationStateNames] = React.useState(
    /*Object.keys(StationState).filter(key=> typeof key === "string") */
    Object.values(StationState)
      .filter((x) => typeof x === "string")
      .map((stateValue: any) => stateValue)
  );

  const calculateStationStatesNumbers = (paramObj: {
    name: string;
    selectedOwner?: string;
    selectedHub?: string;
  }) => {
    let stationsOfOwner: string[] = stations.map(
      (station: Station) => station.id
    );
    if (paramObj.selectedOwner) {
      stationsOfOwner = stations
        .filter((station) => station.owner === paramObj.selectedOwner)
        .map((station: Station) => station.id);
      if (paramObj.selectedHub) {
        stationsOfOwner = stations
          .filter(
            (station) =>
              station.owner === selectedOwner &&
              station.hub === paramObj.selectedHub
          )
          .map((station: Station) => station.id);
      }
    }
    return Number(
      Object.entries(stationToStatesMap).filter(([stationId, value]) => {
        console.log("name value", paramObj.name, value);
        //console.log("count", "1");
        if (typeof stationId === "string") {
          return (
            value.state === paramObj.name && stationsOfOwner.includes(stationId)
          );
        }
        return false;
      }).length
    );
  };

  const [stationsStates, setStationsStates] = React.useState<
    PieChartValueType[]
  >(
    stationStateNames.map((name) => ({
      label: name,
      value: calculateStationStatesNumbers({ name: name }),
    }))
  );

  const [stationsStatesDisplayed, setStationsStatesDisplayed] =
    React.useState<PieChartValueType[]>(stationsStates);

  console.log("stationsStatesDisplayed", [...stationsStatesDisplayed]);

  const [stationsDataDisplayed, setStationsDataDataDisplayed] =
    React.useState<PieChartValueType[]>(stationsData);

  if (!stationDataByOwner[0] || !stationDataByHub[0]) return null;
  console.log("hubsDisplayed");
  console.log([...hubsDisplayed]);

  // accessor functions
  const frequency = (d: LetterFrequency) => d.frequency;

  const value = (d: PieChartValueType) => d.value;
  const value2 = (d: PieChartValueType) => d.value;

  // color scales
  const getBrowserColor = scaleOrdinal({
    domain: ownerNames,
    range: [
      "rgba(255,255,255,0.7)",
      "rgba(255,255,255,0.6)",
      "rgba(255,255,255,0.5)",
      "rgba(255,255,255,0.4)",
      "rgba(255,255,255,0.3)",
      "rgba(255,255,255,0.2)",
      "rgba(255,255,255,0.1)",
    ],
  });
  const ownerColorPalette = selectedOwner
    ? ["rgba(255,255,255,0.9)"]
    : [
        "rgba(255,255,255,0.6)",
        "rgba(255,255,255,0.5)",
        "rgba(255,255,255,0.4)",
        "rgba(255,255,255,0.3)",
        "rgba(255,255,255,0.2)",
        "rgba(255,255,255,0.1)",
      ];

  const hubColorPalette = selectedHub
    ? ["rgba(255,255,255,0.9)"]
    : [
        "rgba(255,255,255,0.6)",
        "rgba(255,255,255,0.5)",
        "rgba(255,255,255,0.4)",
        "rgba(255,255,255,0.3)",
        "rgba(255,255,255,0.2)",
        "rgba(255,255,255,0.1)",
      ];
  const stateColorPalette = {
    "charging": "rgba(20,90,20,0.8)",
    "idle": "rgba(90,90,20,0.6)",
    "offline": "rgba(20,20,91,0.4)",
  };
  const stationColorPalette = [
    "rgba(255,150,255,0.5)",
    "rgba(255,150,255,0.3)",
    "rgba(255,150,255,0.1)",
  ];
  if (width < 10) return null;

  //const classes = useStyles(props.theme);

  const handleNavigateToStation = (stationId: string) => {
    navigate(
      location.pathname.split("/")[0] +
        "/dashboard/transactions/station/" +
        stationId
    );
  };

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = height / 20;

  return (
    <svg width={width} height={height}>
      <GradientSteelPurple id="visx-pie-gradient" />
      <rect
        rx={14}
        width={width}
        height={height}
        fill=/*"url('#visx-pie-gradient')"*/{theme.palette.primary.light}
      />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={
            selectedOwner
              ? ownersDisplayed.filter(({ label }) => label === selectedOwner)
              : ownersDisplayed
          }
          pieValue={value}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie<PieChartValueType>
              {...pie}
              animate={animate}
              getKey={(arc) => arc.data.label}
              onClickDatum={({ data: { label } }) => {
                if (label && !selectedOwner && !selectedHub) {
                  setHubsDisplayed((prev) =>
                    prev.filter((item) =>
                      stations
                        .filter((station) => station.owner === label)
                        .map((station) => station.hub)
                        .includes(item.label)
                    )
                  );
                  setStationsDataDataDisplayed((prev) =>
                    prev.filter((item) =>
                      stations
                        .filter((station) => station.owner === label)
                        .map((station) => station.id)
                        .includes(item.label)
                    )
                  );

                  setStationsStatesDisplayed((prev) => {
                    const tempArray = prev.map((pieObj) => {
                      let updatedObj = {
                        label: pieObj.label,
                        value: calculateStationStatesNumbers({
                          name: pieObj.label,
                          selectedOwner: label,
                        }),
                      };
                      return updatedObj;
                    });
                    console.log("stationsStatesDisplayed", [...tempArray]);
                    return tempArray;
                  });

                  //setSelectedOwner(label);
                  // console.log([...hubsDisplayed]);
                  animate &&
                    setSelectedOwner(
                      selectedOwner && selectedOwner === label ? null : label
                    );
                } else {
                  setOwnersDisplayed(owners);
                  setSelectedOwner(null);
                  setSelectedHub(null);
                  setHubsDisplayed(hubs);
                  setStationsDataDataDisplayed(stationsData);
                  setStationsStatesDisplayed(stationsStates);
                }
              }}
              getColor={(arc) =>
                ownerColorPalette[
                  ownersDisplayed.indexOf(arc.data) % ownerColorPalette.length
                ]
              }
            />
          )}
        </Pie>
        <Pie
          data={
            selectedHub
              ? hubsDisplayed.filter(({ label }) => label === selectedHub)
              : hubsDisplayed
          }
          pieValue={value2}
          outerRadius={radius - donutThickness * 1.1}
          innerRadius={radius - donutThickness * 2}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie<PieChartValueType>
              {...pie}
              animate={animate}
              getKey={(arc) => arc.data.label}
              onClickDatum={({ data: { label, value } }) => {
                if (label && !selectedHub && selectedOwner) {
                  setStationsDataDataDisplayed((prev) =>
                    prev.filter((item) =>
                      stations
                        .filter(
                          (station) =>
                            station.owner === selectedOwner &&
                            station.hub === label
                        )
                        .map((station) => station.id)
                        .includes(item.label)
                    )
                  );
                  setStationsStatesDisplayed((prev) => {
                    const tempArray = prev.map((pieObj) => {
                      let updatedObj = {
                        label: pieObj.label,
                        value: calculateStationStatesNumbers({
                          name: pieObj.label,
                          selectedOwner: selectedOwner,
                          selectedHub: label,
                        }),
                      };
                      return updatedObj;
                    });
                    console.log("stationsStatesDisplayed", [...tempArray]);
                    return tempArray;
                  });
                  animate &&
                    setSelectedHub(
                      selectedHub && selectedHub === label ? null : label
                    );
                } else {
                  setOwnersDisplayed(owners);
                  setSelectedOwner(null);
                  setSelectedHub(null);
                  setHubsDisplayed(hubs);
                  setStationsDataDataDisplayed(stationsData);
                  setStationsStatesDisplayed(stationsStates);
                }
              }}
              getColor={(arc) =>
                hubColorPalette[
                  hubsDisplayed.indexOf(arc.data) % hubColorPalette.length
                ]
              }
            />
          )}
        </Pie>
        <Pie
          data={selectedHub ? stationsDataDisplayed : stationsStatesDisplayed}
          pieValue={value}
          pieSortValues={() => -1}
          outerRadius={radius - donutThickness * 2 * 1.1}
        >
          {(pie) => (
            <AnimatedPie<PieChartValueType>
              {...pie}
              animate={animate}
              getKey={(arc) => arc.data.label + ": " + arc.data.value}
              onClickDatum={(arc) => {
                if (selectedHub) {
                  animate &&
                    console.log(
                      "clicked",
                      handleNavigateToStation(arc.data.label)
                    );
                }
              }}
              getColor={(arc) =>
                selectedHub
                  ? stationColorPalette[
                      stationsDataDisplayed.indexOf(arc.data) %
                        stationColorPalette.length
                    ]
                  : stateColorPalette[
                      arc.data.label as keyof typeof stateColorPalette
                    ]
              }
            />
          )}
        </Pie>
      </Group>
      {animate && (
        <text
          textAnchor="middle"
          x={width / 2}
          y={height * 0.13}
          fill="white"
          fontSize={25}
          fontWeight={300}
          pointerEvents="none"
        >
          {chartLabel}
        </text>
      )}
    </svg>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="white"
              x={
                ownersOuter.includes(getKey(arc).split(":")[0])
                  ? arc.startAngle > Math.PI / 2
                    ? centroidX - 35
                    : centroidX + 35
                  : centroidX
              }
              y={
                ownersOuter.includes(getKey(arc).split(":")[0])
                  ? arc.startAngle > Math.PI / 2
                    ? centroidY - 35
                    : centroidY + 35
                  : centroidY
              }
              dy="0.33em"
              fontSize={13}
              textAnchor="middle"
              pointerEvents="none"
            >
                {getKey(arc)}
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}
