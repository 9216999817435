import { User } from "firebase/auth";
import { collection, Firestore, onSnapshot, query } from "firebase/firestore";
import { ProfileType } from "../../../profiles/typedefs/ProfileType";
import { CloudUser } from "../../../users/typedefs/CloudUser";
import { CurrentUserPermissiontype } from "../../App";

export function setUpSnapshotListener(
  setterCallback: (resultItems: any) => void,
  collectionName: string,
  query: any,
  db: Firestore | null,
  userInfo: User | null
) {
  if (db && userInfo) {
    const userCompany = userInfo.email!.split("@")[1];
    const myQuery = query;
    // update station list if there is a change in Firestore
    const unsubscribe = onSnapshot(
      myQuery,
      (querySnapshot: any) => {
        const resultItems: any = [];
        querySnapshot.forEach((doc: any) => {
          resultItems.push(doc.data());
        });
        setterCallback(resultItems);
      },
      (error) => console.log("{error} " + JSON.stringify(error))
    );
    return () => {
      unsubscribe();
    };
  }
}

export function profilesAreEqual(
  user: CloudUser,
  profile: ProfileType
): boolean {
  return (
    user.permissions &&
    profile.permissions &&
    user.permissions.global &&
    profile.permissions.global &&
    user.permissions.company &&
    profile.permissions.company &&
    permissionsAreEqual(user.permissions.global, profile.permissions.global) &&
    permissionsAreEqual(user.permissions.company, profile.permissions.company)
  );
}

function permissionsAreEqual(
  permissions1: CurrentUserPermissiontype,
  permissions2: CurrentUserPermissiontype
): boolean {
  let flag = true;

  if (!permissions1 || !permissions2) return false;

  Object.entries(permissions1).forEach(([collectionName, CRUDPermissions]) => {
    if (
      (CRUDPermissions &&
        CRUDPermissions.read &&
        permissions2[collectionName as keyof typeof permissions2] &&
        CRUDPermissions.read !=
          permissions2[collectionName as keyof typeof permissions2].read) ||
      (CRUDPermissions &&
        CRUDPermissions.create &&
        permissions2[collectionName as keyof typeof permissions2] &&
        CRUDPermissions.create !=
          permissions2[collectionName as keyof typeof permissions2].create) ||
      (CRUDPermissions &&
        CRUDPermissions.update &&
         permissions2[collectionName as keyof typeof permissions2] &&
        CRUDPermissions.update !=
          permissions2[collectionName as keyof typeof permissions2].update) ||
      (CRUDPermissions &&
        CRUDPermissions.delete &&
         permissions2[collectionName as keyof typeof permissions2] &&
        CRUDPermissions.delete !=
          permissions2[collectionName as keyof typeof permissions2].delete)
    ) {
      flag = false;
    }
  });
  return flag;
}
