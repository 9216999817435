import * as React from "react";
import { useState } from "react";

import {
  collection,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { User } from "firebase/auth";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Stack from "@mui/material/Stack";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Fab,
  TextField,
  Typography,
} from "@mui/material";

import { setUpSnapshotListener } from "../app/apis/firebase/dbUtils";
import Firebase from "../app/apis/firebase/Firebase";

import { profile } from "console";
import UserForm from "./util-components/UserForm";
import { ProfileType } from "../profiles/typedefs/ProfileType";
import UserItem from "./util-components/UserItem";
import { CloudUser, Profile, UserStatus } from "./typedefs/CloudUser";
/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: ProfileType[];
  handleNewUser: (user: CloudUser) => void;
  handleModifiedUser: (user: CloudUser) => void;
  handleDeletedUser: (user: CloudUser) => void;
  handleUpdateUsers: (users: CloudUser[]) => void;
}

/***************************************************************************/

export default function UsersScreen(props: Props) {
  const [newUserTemplate, setNewUserTemplate] = useState<CloudUser>({
    //Default values of a new profile
    Id: "test",
    email: "test@mtholding.hu",
    status: UserStatus.pending,
    profile: "N/A",
    initialPassword: "srgfgbxbdf",
    company: "",
    permissions: {
      company: {
        authTokens: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
        stations: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
        transactions: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
        users: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
      },
      global: {
        authTokens: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        stations: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        transactions: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        users: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
      },
    }
  });

  const [currentExpandedUser, setCurrentExpandedUser] =
    React.useState<CloudUser | null>(null);
  const [showNewProfileForm, setShowNewUserForm] =
    React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  return (
    <Container>
      <Stack spacing={3} mt={5} mb={5} ml={2} mr={2}>
        {showNewProfileForm ? (
          <Stack spacing={3}>
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => setShowNewUserForm(false)}
            >
              <RemoveIcon />
            </Fab>
            <UserForm
              profiles_passedDown={props.profiles}
              user_passedDown={newUserTemplate}
              setUser_passedDown={(newUser: CloudUser) => {
                setNewUserTemplate(newUser);
              }}
              variant="new"
              handleUserModify_PassedDown={(modifiedUser: CloudUser) => {
                props.handleModifiedUser(modifiedUser);
                props.handleUpdateUsers([modifiedUser]);
                setRefresh((prev) => !prev);
              }}
              handleUserDelete_PassedDown={(deletedUser: CloudUser) => {
                props.handleDeletedUser(deletedUser);
                setRefresh((prev) => !prev);
              }}
              handleNewUser_PassedDown={(user: CloudUser) => {
                props.handleNewUser(user);
                props.handleUpdateUsers([user]);
                setShowNewUserForm(false);
                setRefresh((prev) => !prev);
              }}
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={3} alignItems="center">
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => setShowNewUserForm(true)}
            >
              <AddIcon />
            </Fab>
            <Typography variant="h6">Új felhasználó hozzáadása</Typography>
          </Stack>
        )}

        <Divider />
        <Typography variant="h6">Létező felhasználók</Typography>
        <Stack spacing={3}>
          {
          props.profiles && props.profiles.length > 0 &&
          props.users &&
          props.users.length > 0 ? (
            props.users.map((user: CloudUser) => {
              return (
                <UserItem
                  currentlyExpanded_passedDown={user === currentExpandedUser}
                  setCurrentlyExpanded_PassedDown={(user: CloudUser | null) => {
                    setCurrentExpandedUser(user);
                  }}
                  profiles_passedDown={props.profiles}
                  user_passedDown={user}
                  setUser_passedDown={(newUser: CloudUser) => {
                    setNewUserTemplate(newUser);
                  }}
                  variant="modifyDelete"
                  handleUserModify_PassedDown={(modifiedUser: CloudUser) => {
                    props.handleModifiedUser(modifiedUser);
                    props.handleUpdateUsers([user]);
                    setCurrentExpandedUser(null);
                  }}
                  handleUserDelete_PassedDown={(deletedUser: CloudUser) => {
                    props.handleDeletedUser(deletedUser);
                    setCurrentExpandedUser(null);
                  }}
                  handleNewUser_PassedDown={(user: CloudUser) => {
                    props.handleNewUser(user);
                    props.handleUpdateUsers([user]);
                    setCurrentExpandedUser(null);
                    setShowNewUserForm(false);
                  }}
                />
              );
            })
          ) : (
            <Stack
              sx={{ height: "50vh" }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="info" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
