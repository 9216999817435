import React, { Component, JSXElementConstructor } from "react";
import GoogleMapReact, {
  ChangeEventValue,
  ClickEventValue,
} from "google-map-react";
import { Box, fontSize } from "@mui/system";
import { Badge, Container, useMediaQuery } from "@mui/material";
import { CloudUser, Profile } from "../../users/typedefs/CloudUser";
import Firebase from "../../app/apis/firebase/Firebase";
import appTheme from "../../app/theme/AppTheme";
import { MapStyleObj } from "../constants/MapStyles";
import {
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_ZOOM,
} from "../constants/mapDefaultProps";

//const AnyReactComponent = ({ text }) => <div>{text}</div>;

/***************************************************************************/

interface Props {
  onClick: (coord: LocationCoordinates) => void;
  onChange: (e: ChangeEventValue) => void;
  center: LocationCoordinates;
  zoom?: number;
  children:
    | JSX.Element
    | JSX.Element[]
    | JSX.Element[][]
    | (JSX.Element | JSX.Element[])[]
    | null;
}

export type LocationCoordinates = {
  lat: number;
  lng: number;
};

/***************************************************************************/

export default function BaseMap(props: Props) {
  const isSmDown = useMediaQuery(appTheme.breakpoints.down("md"));
  const isSmUp = useMediaQuery(appTheme.breakpoints.up("md"));

  const [mapHeight, setMapHeight] = React.useState(
    calculateMapDimensions(
      isSmUp,
      isSmDown,
      window.innerWidth,
      window.innerHeight
    ).height
  );
  const [mapWidth, setMapWidth] = React.useState(
    calculateMapDimensions(
      isSmUp,
      isSmDown,
      window.innerWidth,
      window.innerHeight
    ).width
  );

  function calculateMapDimensions(
    isSmUp: boolean,
    isSmDown: boolean,
    windowInnerWidth: number,
    windowInnerHeight: number,
  ) {
    if (isSmUp === undefined || isSmDown === undefined)
      return { width: 10, height: 10 };
    if (isSmUp) {
      return {
        width: windowInnerWidth - 280,
        height: windowInnerHeight - 80,
      };
    } else {
      return {
        width: windowInnerWidth,
        height: windowInnerHeight - 80,
      };
    }
  }

  function handleResize() {
    setMapHeight(
      calculateMapDimensions(
        isSmUp,
        isSmDown,
        window.innerWidth,
        window.innerHeight
      ).height
    ); //130px is the TabBar height + AppBar Height
    setMapWidth(
      calculateMapDimensions(
        isSmUp,
        isSmDown,
        window.innerWidth,
        window.innerHeight
      ).width
    ); 
    /*(isSmUp ? 0 : 280)*/ //130px is the TabBar height + AppBar Height
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        //maxWidth: "100vw",
        mt: "0px",
        margin: 0,
        p: 0,
        overflow: "hidden",
      }}
    >
      <GoogleMapReact
        onChange={props.onChange}
        onClick={props.onClick}
        defaultCenter={MAP_DEFAULT_CENTER}
        center={props.center}
        style={{
          position: "relative",
          height: mapHeight + "px",
          width: mapWidth + "px",
          marginTop: "0px",
          margin: 0,
        }}
        defaultZoom={MAP_DEFAULT_ZOOM}
        zoom={props.zoom}
        options={{
          styles: MapStyleObj,
        }}
        bootstrapURLKeys={{
          key: /*"AIzaSyATyvTDlUmR9o_1tVNqMSyx0stPzvJpjeg"*/ "AIzaSyBzSZnBSaHr4RqFuAj_HiIGOafVFpPTWdE",
          language: "hu",
          region: "hu",
          libraries: ["places"],
          v: "beta",
          //id: 'e4c80722b99a2313'
        }}
        //onClick={(e: ClickEventValue) => handleClick(e)}
        //yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }): any => handleApiLoaded(map, maps)}
      >
        {props.children}
      </GoogleMapReact>
    </Box>
  );
}
