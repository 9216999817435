import React from "react";
import { BarStackHorizontal } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleLog, scaleOrdinal } from "@visx/scale";
import { timeParse, timeFormat } from "d3-time-format";
import { withTooltip, Tooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { LegendOrdinal } from "@visx/legend";
import { StationsTransactionsRecordType } from "../../../reports/typedefs/StationsTransactionsRecordType";
import { Stack, Typography } from "@mui/material";
import { Text } from "@visx/text";
import { GradientTealBlue, GradientSteelPurple, GradientOrangeRed, RadialGradient } from "@visx/gradient";
import theme from "../../theme/AppTheme";

type TooltipData = {
  bar: SeriesPoint<any>;
  key: string;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type BarStackHorizontalProps = {
  chartLabel: string;
  XAxisLabelName: string /**kW */;
  data: BarStackDataType[];
  XAxisLogScaleActive: boolean /**kW */;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

export const purple3 = theme.palette.secondary.light;
const keyColorPalette = ["#c998ff", "#6c5efb", "#b839f7"];
export const background = theme.palette.secondary.light;
const defaultMargin = { top: 100, left: 150, right: 50, bottom: 50 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: theme.palette.secondary.light,
  zIndex: 5,
};

let tooltipTimeout: number;

export default withTooltip<BarStackHorizontalProps, TooltipData>(
  ({
    chartLabel,
    data,
    XAxisLogScaleActive,
    XAxisLabelName,
    width,
    height,
    events = false,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }: BarStackHorizontalProps & WithTooltipProvidedProps<TooltipData>) => {
    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const keys: Array<string> = React.useMemo(
      () =>
        data
          ? data[0]
            ? Object.keys(data[0]).filter((key) => key !== "YAxisFieldName_1")
            : []
          : [],
      []
    );

    //console.log("[...Object.keys(data[0])]");
    // console.log([...keys]);

    // accessors
    const getYAxis_1_Value = (d: BarStackDataType) => {
      // console.log(
      //   "const getYAxis_1_Value = (d: BarStackDataType) => d.YAxisFieldName_1;"
      //  );
      //console.log(d.YAxisFieldName_1);
      return d["YAxisFieldName_1"];
    };

    // scales
    const maxXAxisValue = React.useMemo(
      () =>
        Math.max(
          ...data.map((record: BarStackDataType) => {
            let temp = 0;
            Object.keys(record).forEach((key) => {
              if (key !== "YAxisFieldName_1") temp += record[key];
            });
            return temp;
          })
        ),
      [data]
    );

    if (data) {
      console.log("[...data]");
      console.log([...data]);
      data.forEach((data) => {});
      //Object.values()
    }

    const getTotalChargeFromData = (data: any) => {
      return Object.values(data).reduce(
        (previousValue: number, currentValue) => {
          if (typeof currentValue == "number") {
            return previousValue + Number(currentValue);
          }
          return 0;
        },
        0
      );
    };

    /*let maxValueObj = ()=> {
      type myObjType = {
        [k: string]: any | null;
      };
      let myObj: myObjType={};
      data.forEach((data) => {
        if (!myObj[data.YAxisFieldName_1]) {
          myObj[data.YAxisFieldName_1] = Object();
        }
        myObj[data.YAxisFieldName_1]].push(station);
      });
    }*/

    const ScaleXAxis_log = scaleLog<number>({
      domain: [1, maxXAxisValue],
      round: true,
      base: 20,
      nice: true,
      clamp: true,
    });

    const ScaleXAxis_linear = scaleLinear<number>({
      domain: [1, maxXAxisValue],
      nice: true,
    });

    //owners
    const YAxisFieldName_1_keys: any[] = data
      ? data[0]
        ? data.map((data: any) => data["YAxisFieldName_1"])
        : []
      : [];

    const ScaleYAxis = scaleBand<string>({
      //domain: data.map(getYAxis_1_Value),
      domain: Array.from(new Set(YAxisFieldName_1_keys)),
      padding: 0.2,
    });

    const colorScale = React.useMemo(() => {
      return scaleOrdinal<string, string>({
        domain: keys,
        range: keys.map(
          (key, i) => keyColorPalette[i % keyColorPalette.length]
        ),
        //range: keys.map(key=> "#"+Math.floor(Math.random()*13777215).toString(16)),
      });
    }, [keys]);

    ScaleXAxis_log.rangeRound([0, xMax]);
    ScaleXAxis_linear.rangeRound([0, xMax]);
    ScaleYAxis.rangeRound([yMax, 10]);

    // console.log(["...data"])
    //  console.log([...data])

    return width < 10 ? null : (
      <div style={{ maxWidth: "500px" }}>
        <svg width={width} height={height}>
          <GradientSteelPurple id="visx-pie-gradient" />

          <rect
            width={width}
            height={height}
            fill=/*"url('#visx-pie-gradient')"*/ {theme.palette.primary.light}
            rx={14}
          />

          <Group top={margin.top} left={margin.left}>
            <BarStackHorizontal<any, any>
              data={data}
              keys={keys}
              height={yMax}
              y={getYAxis_1_Value}
              //width={150}
              xScale={XAxisLogScaleActive ? ScaleXAxis_log : ScaleXAxis_linear}
              yScale={ScaleYAxis}
              color={colorScale}
            >
              {(barStacks) => (
                <React.Fragment>
                  {console.log([...barStacks])}
                  {console.log([
                    ...barStacks.map((barStack, j) => {
                      let max = 0;
                      let temp = {};
                      /* barStack.bars.forEach((bar, i) => {
                      temp[bar.bar.data.YAxisFieldName_1] += Object.values(bar.bar.data).reduce((previousValue: number, currentValue) => {
                        return previousValue + Number(currentValue);
                      }, 0)
                      if (bar.bar.data > max) {
                        max = bar.bar[1];
                      }
                    });*/
                      console.log(max);
                      return max;
                    }),
                  ])}
                  {barStacks.map((barStack, j) =>
                    barStack.bars.map((bar, i) => (
                      <React.Fragment>
                        <rect
                          key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                          onClick={() => {
                            if (events)
                              alert(`clicked: ${JSON.stringify(bar)}`);
                          }}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={() => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            const top = bar.y + margin.top;
                            const left = bar.x + bar.width + margin.left;
                            if (bar.width > 0) {
                              showTooltip({
                                tooltipData: bar,
                                tooltipTop: top,
                                tooltipLeft: left,
                              });
                            }
                          }}
                        ></rect>
                        {bar.width > 0 &&
                        bar.x ===
                          0 /*bar.bar[1] === barStack.bars[barStack.bars.length-1].bar[1] */ ? (
                          <Text
                            style={{ "padding": "0px", color: "grey", zIndex:4 }}
                            x={bar.x + 5}
                            y={bar.y + bar.height / 2}
                            dy={0}
                            fontSize="20px"
                            fontFamily="arial"
                            fill="white"
                            //textAnchor="middle"
                            /** Vertical text anchor. */
                            verticalAnchor="middle"
                          >
                            {JSON.stringify(
                              getTotalChargeFromData(bar.bar.data)
                            ) +
                              " " +
                              XAxisLabelName}
                          </Text>
                        ) : null}
                      </React.Fragment>
                    ))
                  )}
                </React.Fragment>
              )}
            </BarStackHorizontal>
            <AxisLeft
              hideAxisLine
              hideTicks
              scale={ScaleYAxis}
              stroke={purple3}
              tickStroke={purple3}
              tickLabelProps={() => ({
                fill: purple3,
                fontSize: 14,
                textAnchor: "end",
                dy: "0.33em",
              })}
            />
            <AxisBottom
              top={yMax}
              scale={XAxisLogScaleActive ? ScaleXAxis_log : ScaleXAxis_linear}
              stroke={purple3}
              tickStroke={purple3}
              tickLabelProps={() => ({
                fill: purple3,
                fontSize: 11,
                textAnchor: "middle",
              })}
            />
            <div
              style={{
                position: "relative",
                top: margin.top / 2 - 10,
                width: "100%",
                display: "flex",
                justifyContent: "end",
                fontSize: "14px",
              }}
            >
              <Stack direction="column">
                <LegendOrdinal
                  scale={colorScale}
                  direction="column"
                  labelMargin="0 5px 0 0"
                />
              </Stack>
            </div>
          </Group>
          {
            <text
              textAnchor="middle"
              x={width / 2}
              y={height * 0.13}
              fill="white"
              fontSize={25}
              fontWeight={300}
              pointerEvents="none"
            >
              {chartLabel}
            </text>
          }
        </svg>

        {tooltipOpen && tooltipData && (
          <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div style={{ color: colorScale(tooltipData.key) }}>
              <strong>{tooltipData.key}</strong>
            </div>
            <div>
              {tooltipData.bar.data[tooltipData.key]} {XAxisLabelName}
            </div>
            <div>
              <small>
                {/*formatDate(*/ getYAxis_1_Value(tooltipData.bar.data) /*)*/}
              </small>
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
);
