import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  collection,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { ParentSize } from "@visx/responsive";

import { User } from "firebase/auth";
import { setUpSnapshotListener } from "../app/apis/firebase/dbUtils";
import GeneralBarStack from "../app/charts/GeneralBarStack";
import SpiderDiagram from "../app/charts/SpiderDiagram";
import VisxPieChart from "../app/charts/VisxPieChart";
import { StationsTransactionsRecordType } from "../reports/typedefs/StationsTransactionsRecordType";
import { TokensTransactionsRecordType } from "../reports/typedefs/TokensTransactionsRecordType";
import {
  stationsWithSumTransactions,
  tokensWithSumTransactions,
} from "../reports/util-modules/reportBuilder";
import { convertToBarStackDataType } from "../app/charts/util-modules/convert";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";
import Firebase from "../app/apis/firebase/Firebase";
import VisxBarStack from "../app/charts/util-components/VisxBarStack";
import { StationState } from "../stations/constants/StationState";

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
}

export type StationsToStatesMapType = {
  [stationId: string]: { owner: string; hub: string; state: string };
};

/***************************************************************************/

export default function OverViewScreen(props: Props) {
  const new_stationsWithSumTransactions = React.useMemo(() => {
    console.log("new_stationsWithSumTransactions memo called, txs: ", [
      ...props.transactions,
    ]);
    return (
      stationsWithSumTransactions(
        props.stations,
        props.transactions,
        props.chargeTokens
      ) || []
    );
  }, [props.firebase, props.stations, props.transactions, props.chargeTokens]);

  const data1 = React.useMemo<BarStackDataType[] | null>(() => {
    if (new_stationsWithSumTransactions.length === 0) return null;
    return convertToBarStackDataType<StationsTransactionsRecordType>(
      new_stationsWithSumTransactions,
      {
        YAxisFieldName_1: "_id",
        YAxisFieldName_2: "ownerName",
        XAxisFieldName: "energyTotal",
      }
    );
  }, [new_stationsWithSumTransactions]);

  const data2 = React.useMemo<BarStackDataType[] | null>(() => {
    if (new_stationsWithSumTransactions.length === 0) return null;
    return convertToBarStackDataType<StationsTransactionsRecordType>(
      new_stationsWithSumTransactions,
      {
        YAxisFieldName_1: "_id",
        YAxisFieldName_2: "hubName",
        XAxisFieldName: "energyTotal",
      }
    );
  }, [new_stationsWithSumTransactions]);

  const data3 = React.useMemo<BarStackDataType[] | null>(() => {
    if (new_stationsWithSumTransactions.length === 0) return null;
    console.log("data3 memo called");

    return convertToBarStackDataType<StationsTransactionsRecordType>(
      new_stationsWithSumTransactions,
      {
        YAxisFieldName_1: "_id",
        YAxisFieldName_2: "_id",
        XAxisFieldName: "energyTotal",
      }
    );
  }, [new_stationsWithSumTransactions]);

  const data4 = React.useMemo<BarStackDataType[] | null>(() => {
    if (new_stationsWithSumTransactions.length === 0) return null;

    return convertToBarStackDataType<StationsTransactionsRecordType>(
      new_stationsWithSumTransactions,
      {
        YAxisFieldName_1: "ownerName",
        YAxisFieldName_2: "_id",
        XAxisFieldName: "energyTotal",
      }
    );
  }, [new_stationsWithSumTransactions]);

  const new_tokensWithSumTransactions = React.useMemo(() => {
    return (
      tokensWithSumTransactions(
        props.stations,
        props.transactions,
        props.chargeTokens
      ) || []
    );
  }, [props.firebase, props.stations, props.transactions, props.chargeTokens]);

  const data5 = React.useMemo<BarStackDataType[] | null>(() => {
    if (new_tokensWithSumTransactions.length === 0) return null;
    return convertToBarStackDataType<TokensTransactionsRecordType>(
      new_tokensWithSumTransactions,
      {
        YAxisFieldName_1: "tokenHolderName",
        YAxisFieldName_2: "_id",
        XAxisFieldName: "energyTotal",
      }
    );
  }, [new_tokensWithSumTransactions]);

  if (data1 && data2 && data3) {
    console.log("[...data1]");
    console.log([...data1!]);
    console.log("[...data2]");
    console.log([...data2!]);
    console.log("[...data3]");
    console.log([...data3!]);
  }

  const stationToStatesMap =
    React.useMemo<StationsToStatesMapType | null>(() => {
      let mapObj: StationsToStatesMapType = {};

      const stationsCharging = props.transactions
        .filter((tx) => tx.ended === false)
        .map((tx) => tx.station);

      props.stations.forEach((station: Station) => {
        if (stationsCharging.includes(station.id)) {
          mapObj[station.id] = {
            owner: station.owner,
            hub: station.hub,
            state: StationState[StationState.charging],
          };
        } else if (Date.now() - station.lastHeartbeat < 1000 * 60) {
          mapObj[station.id] = {
            owner: station.owner,
            hub: station.hub,
            state: StationState[StationState.idle],
          };
        } else
          mapObj[station.id] = {
            owner: station.owner,
            hub: station.hub,
            state: StationState[StationState.offline],
          };
      });

      return mapObj;
    }, [new_stationsWithSumTransactions,
      new_tokensWithSumTransactions]);

  console.log("stationToStatesMap", stationToStatesMap);

  return (
    <Container>
      <Grid container spacing={3} mt={0}>
        <Grid item spacing={2} mt={0} xs={12} sm={12} md={12} lg={12} xl={6}>
          <Box
            mt={5}
            sx={{
              m: 0,
              height: "70%",
              width: "100%",
              minHeight: "420px",
              minWidth: "320px",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          >
            {props.stations &&
            props.transactions &&
            props.chargeTokens &&
            props.stations &&
            data1 &&
            data1.length > 0 &&
            data2 &&
            data2.length > 0 &&
            data3 &&
            stationToStatesMap &&
            data3.length > 0 ? (
              <ParentSize>
                {({ width, height }) => (
                  <VisxPieChart
                    chartLabel="Töltők állapota"
                    width={width}
                    height={height}
                    stationDataByOwner={data1}
                    stationDataByHub={data2}
                    stationDataById={data3}
                    stations={props.stations}
                    stationToStatesMap={stationToStatesMap}
                  />
                )}
              </ParentSize>
            ) : (
              <LoadingWidget />
            )}
          </Box>
        </Grid>
        <Grid item spacing={3} mt={0} xs={12} sm={12} md={12} lg={12} xl={6}>
          <Box
            mt={5}
            sx={{
              m: 0,
              height: "70%",
              width: "100%",
              minHeight: "420px",
              minWidth: "320px",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          >
            {data4 ? (
              <ParentSize>
                {({ width, height }) => (
                  <VisxBarStack
                    chartLabel="Átvitt energia cégenként (kWh)"
                    width={width}
                    height={height}
                    XAxisLabelName={"kWh"}
                    data={data4}
                    XAxisLogScaleActive={false}
                  />
                )}
              </ParentSize>
            ) : (
              <LoadingWidget />
            )}
          </Box>
        </Grid>
        <Grid item spacing={3} mt={0} xs={12} sm={12} md={12} lg={12} xl={6}>
          <Box
            mt={5}
            sx={{
              m: 0,
              height: "70%",
              width: "100%",
              minHeight: "420px",
              minWidth: "320px",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          >
            {data5 ? (
              <ParentSize>
                {({ width, height }) => (
                  <VisxBarStack
                    chartLabel="Átvitt energia tokenenként (kWh)"
                    width={width}
                    height={height}
                    XAxisLabelName={"kWh"}
                    data={data5}
                    XAxisLogScaleActive={false}
                  />
                )}
              </ParentSize>
            ) : (
              <LoadingWidget />
            )}
          </Box>
        </Grid>
        <Grid item spacing={3} mt={0} xs={12} sm={12} md={12} lg={12} xl={6}>
          <Box
            mt={5}
            sx={{
              m: 0,
              height: "70%",
              width: "100%",
              minHeight: "420px",
              minWidth: "320px",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          >
            {data3 ? (
              <ParentSize>
                {({ width, height }) => (
                  <VisxBarStack
                    chartLabel="Átvitt energia töltőnként (kWh)"
                    width={width}
                    height={height}
                    XAxisLabelName={"kWh"}
                    data={data3}
                    XAxisLogScaleActive={false}
                  />
                )}
              </ParentSize>
            ) : (
              <LoadingWidget />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box mt={5}></Box>
    </Container>
  );
}

function LoadingWidget() {
  return (
    <Stack
      sx={{ height: "50vh" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="info" />
    </Stack>
  );
}
