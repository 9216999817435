import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReactElement, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JsxElement } from "typescript";
import { Grid } from "@mui/material";
import { TokenStatus } from "../constants/TokenStatus";

/***************************************************************************/


const main = {

}


/***************************************************************************/


interface Props {
  token: ChargeToken;
  dropdownContent: ReactElement;
}

/***************************************************************************/


export default function ChargeTokenItem(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [bgColor, setBgColor] = React.useState<string>("white");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  React.useEffect(() => {
    setExpanded(false);
    const timeOutID = setTimeout(() => {
      setBgColor(
        props.token.status === TokenStatus.Invalid
          ? "#4f83cc"
          : props.token.status === TokenStatus.Expired
          ? "#ded48c"
          : "white"
      );
    }, 100);
    return () => clearTimeout(timeOutID);
  }, [props.token]);

  return (
    <Box width="100%">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ background: bgColor }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {props.token.token}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
              <Typography sx={{ color: "text.secondary" }}>
                {props.token.name? props.token.name: null}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
              <Typography sx={{ color: "text.secondary" }}>
                {props.token.status}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
              <Typography sx={{ color: "text.secondary" }}>
                {" ("+props.token.type+")"}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{props.dropdownContent}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
