import { ProfileType } from "../../profiles/typedefs/ProfileType";
import { ExistingFirestoreCollection } from "./MTFirestore";

// Users

export type CloudUser = {

  Id: string;
  email: string;
  status: UserStatus;
  profile: any;
  initialPassword: string;
  company: string;
  permissions: any;
};


export enum UserStatus {
  pending = "pending",
  active = "active",
  inactive = "inactive",
}

// Profiles & Permissions

export type Profile = {
  label: string;
  permissions: Permission[];
};

export type Permission = {
  collection: ExistingFirestoreCollection;
  scope: Scope;
  crudAuth: CRUDAuth;
};

export enum Scope {
  all = "all",
  company = "company",
  none = "none",
}

export enum CRUDAuth {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
}
