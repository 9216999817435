import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";

import { StationState } from "../../stations/constants/StationState";
import { JsxElement } from "typescript";
import StateSelector from "../../timeplanner/util-components/StateSelector";
import TimeIntervalInput from "../../timeplanner/util-components/TimeIntervalInput";

interface Props {
  item: DateFilter;
  handleSubmit: (item: DateFilter) => void;
  submitButtonColor: any;
  submitButtonVariant: any;
  submitButtonLabel: any;
}

export default function DateFilterIntervalSelector(props: Props) {
  const [dateFilter, setDateFilter] = React.useState<DateFilter>(props.item);

  const onTimeIntervalSelection = (
    selectedStart: Date,
    selectedEnd: Date
  ): void => {
    setDateFilter({
      ...dateFilter,
      ...{ start: selectedStart.getTime(), end: selectedEnd.getTime() },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TimeIntervalInput
          defaultStart={new Date(props.item.start)}
          defaultEnd={new Date(props.item.end)}
          handleIntervalSelection={onTimeIntervalSelection}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button
          color={props.submitButtonColor}
          variant={props.submitButtonVariant}
          sx={{ width: "100%", height: "100%" }}
          onClick={() => props.handleSubmit(dateFilter)}
        >
          {props.submitButtonLabel}
        </Button>
      </Grid>
    </Grid>
  );
}
