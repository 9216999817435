import * as React from "react";
import Box from "@mui/material/Box";
//import stations from "../testdata/stations";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  onSnapshot,
  query,
  where,
  Firestore,
} from "firebase/firestore";

import { Auth, User, UserInfo } from "firebase/auth";
import {
  CircularProgress,
  Collapse,
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DraftsIcon from "@mui/icons-material/Drafts";
import BusinessIcon from "@mui/icons-material/Business";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";

import StationItem from "./util-components/Item";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { setUpSnapshotListener } from "../app/apis/firebase/dbUtils";

import Firebase from "../app/apis/firebase/Firebase";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";
import { withFirebaseDataContext } from "../app/contexts/FirebaseData";
import theme from "../app/theme/AppTheme";
import StationChargingIndicator from "../dashboard/util-components/StationChargingIndicator";
import {
  getIdsOfStationsCharging,
  mapStationsToOwnersAndHubs,
} from "../app/apis/firebase/dataProcessingUtils";

/***************************************************************************/

const main = {
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
};

const typography = {
  color: theme.palette.secondary.contrastText,
  secondaryColor: theme.palette.secondary.dark,
};

const list = {
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
};

const item = {
  background: theme.palette.secondary.main,
  "&:hover, &:focus": {
    bgcolor: theme.palette.secondary.main,
    elevation: 5,
  },
  color: theme.palette.secondary.contrastText,
  fontWeight: "bold",
  fontSize: "15px",
};

const icon = {
  color: theme.palette.secondary.contrastText,
};

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
}

/***************************************************************************/

export default function StationsScreen(props: Props) {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [ownersExpanded, setOwnersExpanded] = React.useState<Set<string>>(
    new Set<string>()
  );
  const [hubsExpanded, setHubsExpanded] = React.useState<Set<string>>(
    new Set<string>()
  );

  const idsOfStationsCharging: string[] =
    // Get the ids of all stations that are currently charging.
    React.useMemo(
      () => getIdsOfStationsCharging(props.transactions),
      [props.transactions]
    );

  const {
    ownersCharging,
    hubsCharging,
  }: { ownersCharging: Set<string>; hubsCharging: Set<string> } =
    // Get the names of all owners and hubs that are currently charging at one of their stations.
    React.useMemo(() => {
      let ownersChargingTemp: Set<string> = new Set<string>();
      let hubsChargingTemp: Set<string> = new Set<string>();
      props.stations.forEach((station: Station) => {
        if (idsOfStationsCharging.includes(station.id)) {
          hubsChargingTemp = hubsChargingTemp.add(station.owner + station.hub);
          ownersChargingTemp = ownersChargingTemp.add(station.owner);
        }
      });
      return {
        ownersCharging: ownersChargingTemp,
        hubsCharging: hubsChargingTemp,
      };
    }, [props.transactions]);

  const stationsToOwnersAndHubsMap: Object =
    // Map all stations to their owners and hubs.
    React.useMemo(
      () => mapStationsToOwnersAndHubs(props.stations),
      [props.stations]
    );

  // Open expand all owners upon load. (this needs to be async)
  React.useEffect(() => {
    const timeOutID = setTimeout(() => {
      setOwnersExpanded(
        new Set<string>(props.stations.map((station) => station.owner))
      );
    }, 0);
    return () => clearTimeout(timeOutID);
  }, [props.stations]);

  return (
    <Container maxWidth={"xl"}>
      <List sx={{ ...list }} color="error" component="nav">
        {Object.entries(stationsToOwnersAndHubsMap).map(
          ([ownerName, hub]: any) => {
            return (
              <React.Fragment>
                <ListItemButton
                  sx={{
                    ...item,
                    borderRadius: "6px",
                    margin: 0.5,
                    height: "80px",
                    boxShadow: 5,
                  }}
                  onClick={() => {
                    setOwnersExpanded((prev: Set<string>) => {
                      if (prev.has(ownerName)) {
                        setHubsExpanded(
                          (prev: Set<string>) =>
                            new Set<string>(
                              Array.from(prev).filter(
                                (item: string) => !item.includes(ownerName)
                              )
                            )
                        );
                        prev.delete(ownerName);
                      } else {
                        prev.add(ownerName);
                      }
                      return new Set<string>(Array.from(prev));
                    });
                  }}
                >
                  <ListItemIcon>
                    <BusinessIcon sx={{ ...icon }} />
                  </ListItemIcon>
                  <ListItemText primary={ownerName} />
                  {ownersExpanded.has(ownerName) ? (
                    <ExpandLess />
                  ) : (
                    <React.Fragment>
                      {ownersCharging.has(ownerName) ? (
                        <StationChargingIndicator sx={{ fontSize: "45px" }} />
                      ) : null}
                      <ExpandMore />
                    </React.Fragment>
                  )}
                </ListItemButton>
                <Collapse
                  in={ownersExpanded.size > 0 && ownersExpanded.has(ownerName)}
                  timeout="auto"
                  unmountOnExit
                >
                  {Object.entries(hub).map(
                    ([hubName, stationsAtHub]: [any, any], index) => {
                      return (
                        <HubList
                          hubName={hubName}
                          ownerName={ownerName}
                          stationsAtHub={stationsAtHub}
                          firebase={props.firebase}
                        />
                      );
                    }
                  )}
                </Collapse>
              </React.Fragment>
            );
          }
        )}
      </List>
    </Container>
  );

  /***************************************************************************/

  interface HubListProps {
    hubName: string;
    ownerName: string;
    stationsAtHub: Station[];
    firebase: Firebase;
  }

  /***************************************************************************/

  function HubList(props: HubListProps) {
    return (
      <List component="div" disablePadding sx={{ width: "100%" }}>
        <ListItemButton
          sx={{
            ...item,
            pl: 2,
            borderRadius: "2px",
            margin: 0.7,
            marginLeft: 1.5,
            marginRight: 0.5,
            height: "60px",
            boxShadow: 2,
          }}
          onClick={() => {
            setHubsExpanded((prev: Set<string>) => {
              prev.has(props.ownerName + props.hubName)
                ? prev.delete(props.ownerName + props.hubName)
                : prev.add(props.ownerName + props.hubName);
              return new Set<string>(Array.from(prev));
            });
          }}
        >
          <ListItemIcon>
            <DeviceHubIcon sx={{ ...icon }} />
          </ListItemIcon>
          <Stack direction="row" spacing={2}>
            <Typography color={typography.secondaryColor} variant="subtitle1">
              Hub {props.hubName}
            </Typography>
            <Typography
              variant="subtitle1"
              color={typography.color}
              component="div"
              sx={{}}
            >
              {" (" + props.stationsAtHub.length + " töltő)"}
            </Typography>
          </Stack>
          <ListItemText primary={""} />
          {hubsExpanded.has(props.ownerName + props.hubName) ? (
            <ExpandLess />
          ) : (
            <React.Fragment>
              {hubsCharging.has(props.ownerName + props.hubName) ? (
                <StationChargingIndicator
                  sx={{
                    mr: 0,
                    mt: "0px",
                    fontSize: "45px",
                  }}
                />
              ) : null}
              <ExpandMore />
            </React.Fragment>
          )}
        </ListItemButton>
        <Collapse
          in={hubsExpanded.has(props.ownerName + props.hubName)}
          timeout="auto"
          unmountOnExit
        >
          <Stack
            direction="column"
            alignItems="right"
            spacing={1}
            ml={3}
            mb={1}
            mt={1}
          >
            {props.stationsAtHub.map((station: Station) => {
              return withFirebaseDataContext(StationItem, {
                station: station,
                firebase: props.firebase,
              });
            })}
          </Stack>
        </Collapse>
      </List>
    );
  }
}
