import * as React from "react";

import {
  Accordion,
  AccordionSummary,
  AppBar,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import BatteryChargingFullOutlinedIcon from "@mui/icons-material/BatteryChargingFullOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";

import sumUpMeterValues from "../util-modules/statistics";
import StatsChip from "./StatsChip";

/***************************************************************************/

interface Props {
  transactions_passedDown: Transaction[];
  variant: "filled" | "outlined";

}

/***************************************************************************/

export default function StatisticsPanel(props: Props) {
  const meterValuesStats: MeterValuesStatsType = React.useMemo(() => {
    let calculatedValues = sumUpMeterValues(props.transactions_passedDown);
    return {
      energyTotal: {
        value: props.transactions_passedDown ? calculatedValues.energyTotal : null,
        unitOfMeasure: {
          customData: {
            vendorId: "MTHolding",
            measurementLabel: "Összesített energia",
            unitShort: "kWh",
            meterValueIcon: BatteryChargingFullOutlinedIcon,
          },
          unit: "Kilowattóra",
          multiplier: -3,
        },
      },
      currentAvg: {
        value: props.transactions_passedDown ? calculatedValues.currentAvg : null,
        unitOfMeasure: {
          customData: {
            vendorId: "MTHolding",
            measurementLabel: "Átlagos áramerősség",
            unitShort: "A",
            meterValueIcon: DoubleArrowOutlinedIcon,
          },
          unit: "Amper",
          multiplier: 0,
        },
      },
      powerAvg: {
        value: props.transactions_passedDown ? calculatedValues.powerAvg : null,
        unitOfMeasure: {
          customData: {
            vendorId: "MTHolding",
            measurementLabel: "Átlagos teljesítmény",
            unitShort: "kW",
            meterValueIcon: ElectricalServicesOutlinedIcon,
          },
          unit: "Kilowatt",
          multiplier: -3,
        },
      },
      voltageAvg: {
        value: props.transactions_passedDown ? calculatedValues.voltageAvg : null,
        unitOfMeasure: {
          customData: {
            vendorId: "MTHolding",
            measurementLabel: "Átlagos feszültség",
            unitShort: "V",
            meterValueIcon: BoltOutlinedIcon,
          },
          unit: "Volt",
          multiplier: 0,
        },
      },
    };
  }, [props.transactions_passedDown]);

  return (
      <Stack spacing={2} mt={1} mb={1}>
        <React.Fragment>
          <Grid container spacing={2}>
            {Object.values(meterValuesStats).map(
              (meterValueStat: StatValueType) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={3} >
                    <StatsChip meterValueStat={meterValueStat} variant={props.variant}/>
                  </Grid>
                );
              }
            )}
          </Grid>
        </React.Fragment>
      </Stack>
  );
}
