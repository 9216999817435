export enum RequiredEnergy {
    A = 5,
    B = 10,
    C = 20,
    D = 50,
    E = 100,
    F = 150,
    G = 200,
  }
  
