import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, useMediaQuery } from "@mui/material";

import { StationState } from "../../stations/constants/StationState";
import IntervalSelector from "./IntervalSelector";
import theme from "../../app/theme/AppTheme";

/***************************************************************************/

interface Props {
  item: ScheduleItem;
  handleSubmit: (item: ScheduleItem) => void;
  submitButtonColor: any;
  submitButtonVariant: any;
  submitButtonLabel: any;
  stations: Station[];
}

/***************************************************************************/

export default function ScheduledInterval(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  React.useEffect(() => {
    setExpanded(false);
  }, [props.item]);

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack
          spacing={5}
          direction={isSmUp? "row": "column"}
          justifyContent="start"
          alignItems="center"
          sx={{ minWidth: "100%", p: 1 }}
        >
          <Typography variant="subtitle1">{props.item.id}</Typography>
          <Typography variant="subtitle1">{props.item.stationId}</Typography>
          <Typography variant="subtitle1">
            {props.item.start.toLocaleString("hu-HU")}
          </Typography>
          <Typography variant="subtitle1">
            {props.item.end.toLocaleString("hu-HU")}
          </Typography>
          <Typography variant="subtitle1">
            {StationState[props.item.state]}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <IntervalSelector
        stations={props.stations}
          item={props.item}
          handleSubmit={props.handleSubmit}
          submitButtonColor="info"
          submitButtonVariant="outlined"
          submitButtonLabel="Módosítás"
        />
      </AccordionDetails>
    </Accordion>
  );
}
