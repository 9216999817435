import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { User } from "firebase/auth";
import {
  collection,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import Firebase from "../app/apis/firebase/Firebase";

import {
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { setUpSnapshotListener } from "../app/apis/firebase/dbUtils";
import TransactionItem from "./util-components/Item";
import StatsPanel from "./util-components/StatsPanel";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";
import ScrollToTop from "../app/routing/ScrollToTop";

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
}

/***************************************************************************/

export default function TransactionsScreen(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const stationId = React.useMemo(() => {
    return location.pathname.split("/")[
      location.pathname.split("/").length - 1
    ];
  }, []);

  const thisStationsTXs = props.transactions.filter(
    (tx) => tx.station === stationId
  );

  //const classes = useStyles(props.theme);

  const currentTransaction = React.useMemo<Transaction | null>(() => {
    if (thisStationsTXs[0] && thisStationsTXs[0].ended === false)
      return thisStationsTXs[0];
    return null;
  }, []);

  const transactionsEndedToday = thisStationsTXs.filter(
    (transaction) =>
      transaction.ended === true &&
      transaction.startTime >= new Date().setUTCHours(0, 0, 0, 0)
  );

  const transactionsEndedEarlier = thisStationsTXs.filter(
    (transaction) =>
      transaction.ended === true &&
      transaction.startTime <= new Date().setUTCHours(0, 0, 0, 0)
  );

  return (
    <Container>
      <Stack spacing={2} mt={5} mb={10}>
        <Typography variant="h2">{stationId}</Typography>
        <StatsPanel
          transactions_passedDown={thisStationsTXs}
          variant="outlined"
        />
        <Typography variant="h5">Folyamatban lévő töltések</Typography>
        {currentTransaction != null ? (
          <Box>
            <StatsPanel
              transactions_passedDown={[currentTransaction]}
              variant="filled"
            />
            <TransactionItem
              transaction_passedDown={currentTransaction}
              minHeight={100}
              inProgress={true}
            />
          </Box>
        ) : thisStationsTXs.length != 0 ? (
          <Typography variant="subtitle2">
            Jelenleg nincs folyamatban töltés
          </Typography>
        ) : (
          <CircularProgress color="success" />
        )}
        <Divider />
        <Typography variant="h5">Mai napon lezárult töltések</Typography>
        {thisStationsTXs != null ? (
          transactionsEndedToday.length > 0 ? (
            <Stack m={5} spacing={2}>
              <StatsPanel
                transactions_passedDown={transactionsEndedToday}
                variant="filled"
              />
              {transactionsEndedToday.map((transaction: Transaction) => (
                <TransactionItem
                  transaction_passedDown={transaction}
                  minHeight={0}
                  inProgress={false}
                />
              ))}
            </Stack>
          ) : (
            <Typography variant="subtitle2">
              A mai napon még nem történt töltés
            </Typography>
          )
        ) : (
          <CircularProgress color="success" />
        )}
        <Divider />
        <Typography variant="h5">Korábban lezárult töltések</Typography>
        {transactionsEndedEarlier.length != 0 ? (
          <Stack m={5} spacing={2}>
            <StatsPanel
              transactions_passedDown={transactionsEndedEarlier}
              variant="filled"
            />
            {transactionsEndedEarlier.map((transaction: Transaction) => (
              <TransactionItem
                transaction_passedDown={transaction}
                minHeight={0}
                inProgress={false}
              />
            ))}
          </Stack>
        ) : (
          <CircularProgress color="success" />
        )}
      </Stack>
    </Container>
  );
}
