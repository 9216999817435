import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import TimeIntervalInput from "./TimeIntervalInput";

import { StationState } from "../../stations/constants/StationState";
import { JsxElement } from "typescript";

interface Props {
  stations: Station[];
  defaultSelected: string;
  handleSelect: (selectedId: string) => void;
}

export default function StationSelector(props: Props) {
  /*
    const [selected, setSelected] = React.useState<StationState>(
    props.defaultSelected
  );
*/

  const onStationSelection = (event: any): void => {
    props.handleSelect(event.target.value as string);
    //setSelected(event.target.value as StationState);
  };

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">Töltő</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.defaultSelected}
          label="Töltő"
          onChange={onStationSelection}
        >
          {props.stations.map((station: Station) => (
            <MenuItem value={station.id}>{station.id}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
