import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Token } from "typescript";
import ChargeTokenStatusSelector from "./StatusSelector";
import ChargeTokenTypeSelector from "./TypeSelector";
import { TokenStatus } from "../constants/TokenStatus";
import { TokenType } from "../constants/TokenType";

interface Props {
  chargeToken_PassedDown: ChargeToken;
  handleTokenModify_PassedDown: (modifiedToken: ChargeToken) => void;
  handleTokenDelete_PassedDown: (deletedToken: ChargeToken) => void;
  modifyButtonColor: any;
  modifyButtonText: any;
  deleteButtonColor: any;
  deleteButtonText: any;
}
export default function ModifyOrDeleteForm(props: Props) {
  const [chargeToken, setChargeToken] = React.useState<ChargeToken>(props.chargeToken_PassedDown);

  return (
    <Box sx={{ mt: "25px", height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <ChargeTokenStatusSelector
            chargeToken_PassedDown={chargeToken}
            setChargeToken_PassedDown={setChargeToken}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            color={props.modifyButtonColor}
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() => props.handleTokenModify_PassedDown(chargeToken)}
          >
            {props.modifyButtonText}
          </Button>
         
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
        <Button
            color={props.deleteButtonColor}
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() => props.handleTokenDelete_PassedDown(chargeToken)}
          >
            {props.deleteButtonText}
          </Button>

        </Grid>
      </Grid>
    </Box>
  );
}
