import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

import { PermissionType } from "../typedefs/PermissionType";
import { ScopeEnum } from "../constants/ScopeEnum";
import { ExistingFirestoreCollection } from "../../users/typedefs/MTFirestore";
import { CurrentUserPermissiontype } from "../../app/App";

/***************************************************************************/

interface Props {
  collection_passedDown: string;
  CRUDPermissions_passedDown: any;
  setCRUDPermissions_passedDown: (CRUDpermissions: any) => void;
}

/***************************************************************************/

export default function PermissionForm(props: Props) {
 // console.log("in Permission component: ");
 console.log('///{...props.CRUDPermissions_passedDown} ', {...props.CRUDPermissions_passedDown});

 console.log("/// PermissionForm rendered")


  return (
    <FormGroup
      row
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid container alignItems="center">
        
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} spacing={4}>
          <Box>{props.collection_passedDown}</Box>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} spacing={4}>
          {Object.entries(props.CRUDPermissions_passedDown).map(
            ([crudOperation, value]: [string, any]) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        let newSelectedPermissions =
                          props.CRUDPermissions_passedDown;
                        newSelectedPermissions[crudOperation] =
                          event.target.checked as boolean;
                        console.log({...newSelectedPermissions}, '///{...newSelectedPermissions}');
                        props.setCRUDPermissions_passedDown(newSelectedPermissions);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  sx={{ width: "90px" }}
                  label={crudOperation}
                />
              );
            }
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2} spacing={4}>
          {/*
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.CRUDPermissions_passedDown.scope === ScopeEnum.all}
                  onChange={(event: any) => {
                    props.setPermission_passedDown({
                      ...props.permission_passedDown,
                      scope: event.target.checked
                        ? ScopeEnum.all
                        : ScopeEnum.company,
                    });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  color="error"
                />
              }
              sx={{ minWidth: "90px" }}
              label="Minden cég"
            />
            */}
        </Grid>
      </Grid>
    </FormGroup>
  );
}
