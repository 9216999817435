import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReactElement, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JsxElement } from "typescript";
import { Button, Divider, FormControl, FormGroup, Grid } from "@mui/material";
import { ProfileType } from "../typedefs/ProfileType";
import { PermissionType } from "../typedefs/PermissionType";
import PermissionForm from "./PermissionForm";
import ProfileForm from "./ProfileForm";
import { CloudUser } from "../../users/typedefs/CloudUser";

/***************************************************************************/

interface Props {
  handleUpdateUsers_passedDown: (users: CloudUser[]) => void;
  users_passedDown: CloudUser[];
  profile_passedDown: ProfileType;
  currentlyExpanded_passedDown: boolean;
  setCurrentlyExpanded_PassedDown: (profile: ProfileType | null) => void;
  //setProfile_passedDown: (changedProfile: ProfileType) => void;
  variant: "modifyDelete" | "new";
  handleProfileModify_PassedDown: (modifiedProfile: ProfileType) => void;
  handleProfileDelete_PassedDown: (deletedProfile: ProfileType) => void;
  handleNewProfile_PassedDown: (profile: ProfileType) => void;
}

/***************************************************************************/

export default function Profile(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [bgColor, setBgColor] = React.useState<string>("white");

  console.log("/// Profile rendered")

  const [selectedProfile, setSelectedProfile] = React.useState<ProfileType | null>(
    null
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  //  console.log("in Profile component: ");
  //console.log(Object.entries(selectedProfile));

  return (
    <Box>
      <Accordion
        sx={{
          background: props.currentlyExpanded_passedDown ? "#f5f6f7" : "white",
        }}
        expanded={props.currentlyExpanded_passedDown}
        onChange={() => {
          if (props.currentlyExpanded_passedDown) {
            setExpanded(false);
            props.setCurrentlyExpanded_PassedDown(null);
          } else {
            props.setCurrentlyExpanded_PassedDown(props.profile_passedDown);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Profil ID: {selectedProfile? selectedProfile.Id : props.profile_passedDown.Id}
          </Typography>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Profil Neve: {selectedProfile? selectedProfile.label: props.profile_passedDown.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            <ProfileForm
              handleUpdateUsers_passedDown={props.handleUpdateUsers_passedDown}
              users_passedDown={props.users_passedDown}
              profile_passedDown={selectedProfile? selectedProfile: props.profile_passedDown }
              setProfile_passedDown={setSelectedProfile}
              variant={props.variant}
              handleProfileModify_PassedDown={
                props.handleProfileModify_PassedDown
              }
              handleProfileDelete_PassedDown={
                props.handleProfileDelete_PassedDown
              }
              handleNewProfile_PassedDown={props.handleNewProfile_PassedDown}
            />
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
