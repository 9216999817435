import * as React from "react";

import Box from "@mui/material/Box";
import EvStationIcon from "@mui/icons-material/EvStation";
import { StationState } from "../../stations/constants/StationState";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import {
  Badge,
  Button,
  Chip,
  ClickAwayListener,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToMapLocation } from "../util-modules/mapUtils";
import { StationsToStatesMapType } from "../../overview/OverViewScreen";

import HideSourceIcon from "@mui/icons-material/HideSource";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UIEvent } from "react";
import StationChargingIndicator from "../../dashboard/util-components/StationChargingIndicator";
import StationIdleIndicator from "../../dashboard/util-components/StationIdleIndicator";
import StationOfflineIndicator from "../../dashboard/util-components/StationOfflineIndicator";
/***************************************************************************/

interface Props {
  stationsAtThisLocation_passedDown: Station[];
  stationToStatesMap_passedDown: StationsToStatesMapType;
  lat: number;
  lng: number;
  zoom: number;
  text: string;
  group: string;
  groupSize: number;
  refreshMap?: () => void;
}

/***************************************************************************/

/***************************************************************************/

export default function GroupMarker(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [touchClickEnabled, setTouchClickEnabled] =
    React.useState<boolean>(false);

  function handleClick() {
    navigateToMapLocation(
      location,
      navigate,
      { lat: props.lat, lng: props.lng },
      props.zoom
    );
  }

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
    },
  }));

  return (
    <div key={props.group}>
      <ClickAwayListener
        key={props.group}
        onClickAway={() => {
          handleTooltipClose();
          setTouchClickEnabled((prev) => !prev);
        }}
      >
        <Badge badgeContent={props.groupSize} color="secondary">
          <BootstrapTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            sx={{
              backgroundColor: "none",
              //display: "flex",
              //justifyItems: "space-between",
            }}
            placement="bottom-start"
            arrow
            title={
              <Stack
                direction="column"
                spacing={1}
                //justifyContent="space-between"
                sx={{ minWidth: "220px" }}
              >
                <Typography variant="subtitle1" color="primary">Töltők:</Typography>
                {props.stationsAtThisLocation_passedDown &&
                props.stationToStatesMap_passedDown ? (
                  props.stationsAtThisLocation_passedDown.length > 0 ? (
                    props.stationsAtThisLocation_passedDown.map(
                      (station: Station) => {
                        return (
                          <StationButton
                            stationId={station.id}
                            stationStatus={
                              props.stationToStatesMap_passedDown[station.id]
                                .state
                            }
                          />
                        );
                      }
                    )
                  ) : (
                    <p>No stations at this location</p>
                  )
                ) : null}
              </Stack>
            }
          >
            <DeviceHubIcon
              key={props.group}
              onClick={(e: UIEvent) => {
                handleTooltipOpen();
                setTouchClickEnabled((prev) => !prev);
                if (touchClickEnabled) {
                  handleClick();
                }
              }}
              fontSize="large"
              //viewBox="0 0 2 2"
              sx={{
                fontSize: "80",
                minWidth: "0px",
                color: "black",
                background: "none",
              }}
            />
          </BootstrapTooltip>
        </Badge>
      </ClickAwayListener>
    </div>
  );
}

interface StationButtonProps {
  stationId: string;
  stationStatus: string;
}

function StationButton(props: StationButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      sx={{ backgroundColor: "#dedede", maxHeight:"28px" }}
      color={
        props.stationStatus === "charging"
          ? "warning"
          : props.stationStatus === "idle"
          ? "success"
          : props.stationStatus === "offline"
          ? "error"
          : "info"
      }
      onClick={() => {
        navigate(
          location.pathname.split("/")[0] +
            "/dashboard/transactions/station/" +
            props.stationId
        );
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyItems="space-between"
        sx={{ minWidth: "0px" }}
        p={0}
      >
        {props.stationStatus === "charging" ? (
          <StationChargingIndicator
          sx={{
            mr: 0,
            mt: "0px",
            fontSize: "25px",
          }}
        />


        ) : props.stationStatus === "idle" ? (
          <StationIdleIndicator
          sx={{
            mr: 0,
            ml: "0px",
            mt: "0px",
            fontSize: "25px",
          }}
        />
        ) : props.stationStatus === "offline" ? (
          <StationOfflineIndicator
          sx={{
            mr: 0,
            ml: "0px",
            mt: "0px",
            fontSize: "25px",
          }}
        />
        ) : null}

        <Typography sx={{ fontSize:'12px'}}>{props.stationId}</Typography>
        <ArrowForwardIosIcon />
      </Stack>
    </Button>
  );
}
