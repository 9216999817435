import * as React from "react";

import {
  Accordion,
  AccordionSummary,
  AppBar,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import BatteryChargingFullOutlinedIcon from "@mui/icons-material/BatteryChargingFullOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";

import sumUpMeterValues from "../util-modules/statistics";

/***************************************************************************/

interface Props {
  meterValueStat: StatValueType;
  variant: "filled" | "outlined";
}

/***************************************************************************/

export default function StatsChip(props: Props) {
  return (
    <Chip
    variant={props.variant}
    sx={{ minWidth: "250px"}}
      avatar={<props.meterValueStat.unitOfMeasure.customData.meterValueIcon />}
      label={
        <Stack
          sx={{ minWidth: "160px", alignItems: "center" }}
          justifyContent="space-between"
          direction="row"
          spacing={1}
        >
          {props.meterValueStat.value ? (
            <React.Fragment>
              <span>
                {props.meterValueStat.unitOfMeasure.customData.measurementLabel}
                :
              </span>
              <span>{Math.round(props.meterValueStat.value * Math.pow(10, props.meterValueStat.unitOfMeasure.multiplier))}</span>
              <span>
                {props.meterValueStat.unitOfMeasure.customData.unitShort }
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
               <span>
                {props.meterValueStat.unitOfMeasure.customData.measurementLabel}
                :
              </span>
              <CircularProgress
                sx={{
                  maxWidth: "15px",
                  maxHeight: "15px",
                  marginRight: "6px",
                }}
              />
            </React.Fragment>
          )}
        </Stack>
      }
      size="medium"
    />
  );
}
