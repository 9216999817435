import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Popover, Stack } from "@mui/material";

import { StationState } from "../../stations/constants/StationState";
import DateFilterIntervalSelector from "./DateFilterIntervalSelector";

import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Box,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  collection,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { TransitionGroup } from "react-transition-group";

import { User } from "firebase/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../../app/theme/AppTheme";

/***************************************************************************/

interface Props {
  dateFilters_passedDown: DateFilter[];
  setDateFilters_passedDown: (any: any) => void;
}

/***************************************************************************/

export default function FilterInterval(props: Props) {
  const handleNewDateFilter = (newDateFilter: DateFilter) => {
    props.setDateFilters_passedDown((prev: DateFilter[]) =>
      [...prev, { ...newDateFilter, id: prev.length }].sort(
        (a, b) => a.start - b.start
      )
    );
    handleClose();
  };

  const handleDeleteDateFilter = (filterToBeDeleted: DateFilter) => {
    props.setDateFilters_passedDown((prev: DateFilter[]) => {
      return prev.filter((filter: DateFilter) => filter !== filterToBeDeleted);
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const addFruitButton = (
    <Button variant="contained" onClick={handleClick} 
    color="secondary" 
    sx={{minWidth: "100%", 
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText }}>
      <Typography variant="subtitle1" color="white" sx={{minWidth: "100%"}}>
        Dátum szűrő hozzáadása
      </Typography>
    </Button>
  );

  return (
    <Box sx={{ m: 1, /*height: "50px"*/ }}>
      {addFruitButton}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ m: 3, minHeight: "50px" }}>
          <DateFilterIntervalSelector
            item={{
              id: -1,
              start: new Date().getTime(),
              end: new Date().getTime(),
            }}
            handleSubmit={handleNewDateFilter}
            submitButtonColor="success"
            submitButtonVariant="outlined"
            submitButtonLabel="Új"
          />
        </Box>
      </Popover>
      <Box sx={{ mt: 0, maxWidth: "500px"}}>
        <List>
          <TransitionGroup>
            {props.dateFilters_passedDown.length > 0 ? 
            props.dateFilters_passedDown.map((item) => (
              <Collapse
                key={item ? item.start.toString() +  item.end.toString() : null}
              >
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      title="Delete"
                      onClick={() => handleDeleteDateFilter(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ minWidth: "50%", p: 1 }}
                  >
                    <Typography variant="subtitle1">
                      {new Date(item.start).toLocaleString("hu-HU")}
                    </Typography>
                    <Typography variant="subtitle1">
                      {" -> "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {new Date(item.end).toLocaleString("hu-HU")}
                    </Typography>
                  </Stack>
                </ListItem>
              </Collapse>
            )): null}
          </TransitionGroup>
        </List>
      </Box>
    </Box >
  );
}
