import React, { Component } from "react";
import GoogleMapReact, {
  ChangeEventValue,
  ClickEventValue,
} from "google-map-react";
import { Box, fontSize } from "@mui/system";
import { Badge, Container, useMediaQuery } from "@mui/material";
import EvStationIcon from "@mui/icons-material/EvStation";
import Marker from "./util-components/StationMarker";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";
import Firebase from "../app/apis/firebase/Firebase";
import appTheme from "../app/theme/AppTheme";
import { StationState } from "../stations/constants/StationState";
import { StationsToStatesMapType } from "../overview/OverViewScreen";
import GroupMarker from "./util-components/GroupMarker";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import CustomMarker from "./util-components/StationMarker";
import BaseMap from "./util-components/BaseMap";
import {
  calculateAvgLatitude,
  calculateAvgLongitude,
  createLocationToStationMap,
  createStationToStatesMap,
  navigateToMapLocation,
} from "./util-modules/mapUtils";
import {
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_ZOOM,
} from "./constants/mapDefaultProps";

//const AnyReactComponent = ({ text }) => <div>{text}</div>;

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
  onClick: (coord: LocationCoordinates) => void;
}

/***************************************************************************/

export default function StationMap(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { lat, lng } = useParams<string>();
  let { zoom } = useParams<string>();

  const [onlyShowHubIcon, setOnlyShowHubIcon] = React.useState<boolean>(true);
  const [refreshMap, setRefreshMap] = React.useState<boolean>(false);
  /*
  React.useEffect(() => {
    const timeOutID = setTimeout(() => {
      setUserInfo(firebase.userInfo);
      setDb(getFirestore(firebase.app));
    }, 1000);
    return () => clearTimeout(timeOutID);
  }, [lat, lng, zoom, props.transactions, props.stations]);
*/
  const stationToStatesMap = React.useMemo<StationsToStatesMapType | null>(
    () => createStationToStatesMap(props.transactions, props.stations),
    [lat, lng, zoom, props.firebase, props.transactions, props.stations]
  );

  const locationToStationMap = React.useMemo<LocationToStationMapType | null>(
    () => createLocationToStationMap(props.stations),
    [lat, lng, zoom, props.firebase, props.transactions, props.stations]
  );

  console.log("map rerendered*************************");

  return (
    <BaseMap
      onClick={() => {}}
      onChange={(e: ChangeEventValue) => {
        if (e.zoom < 17 && !onlyShowHubIcon) {
          setOnlyShowHubIcon(true);
          navigateToMapLocation(
            location,
            navigate,
            { lat: e.center.lat, lng: e.center.lng },
            e.zoom
          );
        }
        if (e.zoom >= 17 && onlyShowHubIcon) {
          setOnlyShowHubIcon(false);
          /*navigateToMapLocation(
            location,
            navigate,
            { lat: e.center.lat, lng: e.center.lng },
            e.zoom
          );*/
        }
      }}
      center={{
        lat: lat ? Number(lat) : MAP_DEFAULT_CENTER.lat,
        lng: lng ? Number(lng) : MAP_DEFAULT_CENTER.lng,
      }}
      zoom={zoom ? Number(zoom) : MAP_DEFAULT_ZOOM}
    >
      {locationToStationMap && stationToStatesMap
        ? //&& stationsInGroup
          Object.values(locationToStationMap).map(
            (stationsAtThisLocation: Station[], index) => {
              if (onlyShowHubIcon) {
                console.log("[...stationsAtThisLocation]", [
                  ...stationsAtThisLocation,
                ]);
                console.log(
                  "map rerendered temp calc lat: ",
                  calculateAvgLatitude(stationsAtThisLocation)
                );
                console.log(
                  "map rerendered temp calc lng: ",
                  calculateAvgLongitude(stationsAtThisLocation)
                );
                return (
                  <GroupMarker
                    key={String(index)}
                    stationsAtThisLocation_passedDown={stationsAtThisLocation}
                    stationToStatesMap_passedDown={stationToStatesMap}
                    lat={calculateAvgLatitude(stationsAtThisLocation)}
                    lng={calculateAvgLongitude(stationsAtThisLocation)}
                    zoom={18}
                    text="My Marker"
                    group={String(index)}
                    groupSize={stationsAtThisLocation.length}
                    refreshMap={() => setRefreshMap((prev) => !prev)}
                  />
                );
              } else {
                return stationsAtThisLocation.map((station: Station) => {
                  return (
                    <CustomMarker
                      stationState={
                        stationToStatesMap[station.id]
                          ? stationToStatesMap[station.id].state
                          : ""
                      }
                      station={station}
                      lat={station.latitude}
                      lng={station.longitude}
                      text="My Marker"
                      group={String(index)}
                      //refreshParent={()=>setRefresh(prev=>!prev)}
                    />
                  );
                });
              }
            }
          )
        : null}
    </BaseMap>
  );
}
