import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { ChargeMode } from "../constants/ChargeMode";
import { ChargePower } from "../constants/ChargePower";
import { RequiredEnergy } from "../constants/RequiredEnergy";
import theme from "../../app/theme/AppTheme";

interface Props {
  defaultSettings: ChargeSettings;
  onSubmit?: (settings: ChargeSettings) => void;
  submitButtonColor: any;
  submitButtonVariant: any;
  submitButtonLabel: any;
  handleSubmit: (settings: ChargeSettings) => void;
}
export default function ChargeParametersForm(props: Props) {
  const [chargeSettings, setChargeSettings] = React.useState<ChargeSettings>(
    props.defaultSettings
  );

  
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={{ mt: "25px", height: "100%" }}>
      <Stack direction={isSmUp? "row": "column"} spacing={5} sx={{ height: "100%" }}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Mode</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chargeSettings.mode}
            label="Power"
            onChange={(event: any) =>
              setChargeSettings((prevSettings) => {
                return {
                  ...prevSettings,
                  mode: event.target.value as ChargeMode,
                };
              })
            }
          >
            {Object.values(ChargeMode)
              .filter((x) => typeof x === "number")
              .map((value: any) => (
                <MenuItem value={value}>{ChargeMode[value]}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-label">Power</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chargeSettings.power}
            label="Power"
            onChange={(event: any) =>
              setChargeSettings((prevSettings) => {
                return {
                  ...prevSettings,
                  power: event.target.value as ChargePower,
                };
              })
            }
          >
            {Object.values(ChargePower)
              .filter((x) => typeof x === "number")
              .map((value: any) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-label">Required Energy</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chargeSettings.requiredEnergy}
            label="Required Energy"
            onChange={(event: any) =>
              setChargeSettings((prevSettings) => {
                return {
                  ...prevSettings,
                  requiredEnergy: event.target.value as RequiredEnergy,
                };
              })
            }
          >
            {Object.values(RequiredEnergy)
              .filter((x) => typeof x === "number")
              .map((value: any) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
        disabled
          color={props.submitButtonColor}
          variant={props.submitButtonVariant}
          sx={{ width: "100%" /*height: "100%" */ }}
          onClick={() => props.handleSubmit(chargeSettings)}
        >
          {props.submitButtonLabel}
        </Button>
      </Stack>
    </Box>
  );
}
