import React, { useMemo, useCallback } from "react";
import { AreaClosed, Line, Bar } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { GridRows, GridColumns } from "@visx/grid";
import { scaleTime, scaleLinear } from "@visx/scale";
import {
  withTooltip,
  Tooltip,
  TooltipWithBounds,
  defaultStyles,
} from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { max, extent, bisector } from "d3-array";
import { timeFormat } from "d3-time-format";
import {
  MeterValueType,
  MeasurandEnumType,
  SampledValueType,
} from "mtcharger-messages/dist/MeterValuesRequest";
import theme from "../../app/theme/AppTheme";

type TooltipData = MeterValueType;

export const background = "#002171" ;
export const background2 = "#5472d3";
export const accentColor = "#ffffff";
export const accentColorDark = "yellow";
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: "1px solid white",
  color: "white",
};

// util
const formatDate = timeFormat("%I:%M:%S" /*  %b %d, '%y"*/);

export type AreaProps = {
  width: number;
  height: number;
  meterValues: MeterValueType[];
  measurand: MeasurandEnumType;
  margin?: { top: number; right: number; bottom: number; left: number };
  interpolate: () => void;
  renderFreqMs: number;
  freezeTime_PassedDown: boolean;
  ended: boolean;
  setFreezeTime_PassedDown: (freezeTime: boolean) => void;
};

export default withTooltip<AreaProps, TooltipData>(
  ({
    width,
    height,
    meterValues,
    measurand,
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    interpolate,
    renderFreqMs,
    freezeTime_PassedDown,
    setFreezeTime_PassedDown,
    ended,
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    if (width < 10) return null;

    // accessors
    const getDate = (meterValue: MeterValueType): Date => {
      return new Date(parseInt(meterValue.timestamp));
    };

    const getmeterValuesValue = (meterValue: MeterValueType): number => {
      let filteredValues = meterValue.sampledValue.filter(
        (x) => x.measurand === measurand
      );

      if (
        filteredValues[0].measurand &&
        filteredValues[0].measurand.split(".")[0] === "Energy"
      ) {
        return filteredValues[0].value / 3600000;
      }
      if (filteredValues[0].measurand && measurand.split(".")[0] === "Power") {
        return filteredValues[0].value / 1000;
      }

      return filteredValues[0].value;
    };

    const generateZeroMeterValue = (
      meterValueSample: MeterValueType
    ): MeterValueType => {
      let zeroSampledValue: SampledValueType[] =
        meterValueSample.sampledValue.map(({ measurand, value }) => ({
          measurand: measurand,
          value: 0,
        }));
      let zeroMeterValue: MeterValueType = {
        timestamp: Date.now().toString(),
        sampledValue: zeroSampledValue,
      };
      return zeroMeterValue;
    };

    const zeroMeterValue: MeterValueType = generateZeroMeterValue(
      meterValues[meterValues.length - 1]
    );

    const bisectDate = bisector<MeterValueType, Date>(
      (d) => new Date(parseInt(d.timestamp))
    ).left;

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    function tick() {
      if (freezeTime_PassedDown) return;
      setGridTime(Date.now());
      interpolate();
    }

    let intervalID: any = 0;

    const [gridTime, setGridTime] = React.useState(Date.now());

    let dateScale: any;

    const meterValuesScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: measurand
            ? measurand.split(".")[0] === "Current"
              ? [0, 100]
              : measurand.split(".")[0] === "Energy"
              ? [0, 300]
              : measurand.split(".")[0] === "Power"
              ? [0, 140]
              : measurand.split(".")[0] === "SoC"
              ? [0, 100]
              : measurand.split(".")[0] === "Voltage"
              ? [0, 1000]
              : [0, 500]
            : [0, 500],
          nice: false,
        }),
      [margin.top, innerHeight]
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        console.log("handleTooltip called");
        setFreezeTime_PassedDown(true);
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x);
        const index = bisectDate(meterValues, x0, 0);
        const zeroData = {
          ...zeroMeterValue,
          timestamp: x0.valueOf().toString(),
        }; //meterValues[index-1];
        const properData = meterValues[index];
        let d = properData && getDate(properData) ? properData : zeroData;
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: meterValuesScale(getmeterValuesValue(d)),
        });
      },
      [showTooltip, meterValuesScale, dateScale]
    );

    if (!ended) {
      React.useEffect(() => {
        intervalID = setInterval(tick, renderFreqMs);
        return () => {
          clearInterval(intervalID);
        };
      });
      dateScale = React.useMemo(
        () =>
          scaleTime({
            range: [margin.left, innerWidth + margin.left],
            domain: /*extent(meterValues, getDate )*/ [
              new Date(Date.now() - 1000 * 60 * 5 /*/ 60*/),
              new Date(Date.now() + 1000 * 60 * 1 /*/60*/),
            ] as [Date, Date],
          }),
        [innerWidth, margin.left, gridTime]
      );
    } else {
      React.useEffect(() => {
        /*intervalID = setInterval(tick, renderFreqMs);	
        return () => {	
          clearInterval(intervalID);	
        };*/
      });
      dateScale = React.useMemo(() => {
        meterValues.map((x) =>
          console.log(
            "measurand: " + measurand,
            getmeterValuesValue(x),
            getDate(x).toLocaleString("hu-HU")
          )
        );
        return scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: /*extent(meterValues, getDate)*/ [
            new Date(parseInt(meterValues[0].timestamp)),
            new Date(parseInt(meterValues[meterValues.length - 1].timestamp)),
          ] as [Date, Date],
        });
      }, [innerWidth, margin.left, meterValues]);
    }

    return (
      <div>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={14}
          />
          <LinearGradient
            id="area-background-gradient"
            from={background}
            to={background2}
          />
          <LinearGradient
            id="area-gradient"
            from={accentColor}
            to={accentColor}
            toOpacity={0.1}
          />
          <GridRows
            left={margin.left}
            scale={meterValuesScale}
            width={innerWidth}
            strokeDasharray="5,5"
            stroke={accentColor}
            strokeOpacity={0.7}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="10,3"
            stroke={accentColor}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          <AreaClosed<MeterValueType>
            data={meterValues}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => meterValuesScale(getmeterValuesValue(d)) ?? 0}
            yScale={meterValuesScale}
            strokeWidth={10}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            opacity={0.5}
            curve={curveMonotoneX}
          />
          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => {
              hideTooltip();
              setFreezeTime_PassedDown(false);
            }}
          />
          <Line
            from={{ x: dateScale(Date.now()), y: margin.top }}
            to={{ x: dateScale(Date.now()), y: innerHeight + margin.top }}
            stroke="red"
            strokeWidth={2}
            pointerEvents="none"
            strokeDasharray="5,2"
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop - 0.01}
              left={tooltipLeft + 0.01}
              style={tooltipStyles}
            >
              {`${getmeterValuesValue(tooltipData)}`}
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + margin.top - 14}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 100,
                maxWidth: 100,
                textAlign: "center",
                transform: "translateX(-100%)",
              }}
            >
              {getDate(tooltipData).toLocaleString("hu-HU")}
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);
