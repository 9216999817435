import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import TimeIntervalInput from "./TimeIntervalInput";

import { StationState } from "../../stations/constants/StationState";
import { JsxElement } from "typescript";

interface Props {
  defaultSelected: StationState;
  handleSelect: (selected: StationState) => void;
}

export default function StateSelector(props: Props) {
  /*
    const [selected, setSelected] = React.useState<StationState>(
    props.defaultSelected
  );
*/

  const onStationStateSelection = (event: any): void => {
    props.handleSelect(event.target.value as StationState);
    //setSelected(event.target.value as StationState);
  };

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">Állapot</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.defaultSelected}
          label="Állapot"
          onChange={onStationStateSelection}
        >
          {Object.values(StationState)
            .filter((x) => typeof x === "number")
            .map((stateValue: any) => (
              <MenuItem value={stateValue}>{StationState[stateValue]}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
