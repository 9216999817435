import * as React from "react";

import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import EvStationTwoToneIcon from "@mui/icons-material/EvStationTwoTone";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PieChartIcon from '@mui/icons-material/PieChart';
import MapIcon from '@mui/icons-material/Map';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';

import {
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_ZOOM,
} from "../../map/constants/mapDefaultProps";

const dashboardMenu: Menu[] = [
  {
    headerId: "Monitorozás",
    children: [
      {
        id: "Összesítés",
        label: "Összesítés",
        icon: <PieChartIcon />,
        route: "/dashboard/overview",
      },
      {
        id: "Töltők Listája",
        label: "Töltők Listája",
        icon: <EvStationTwoToneIcon />,
        route: "/dashboard/stations/list",
      },
      {
        id: "Térkép",
        label: "Térkép",
        icon: <MapIcon />,
        route:
          "/dashboard/map/lat/" +
          MAP_DEFAULT_CENTER.lat +
          "/lng/" +
          MAP_DEFAULT_CENTER.lng +
          "/zoom/" +
          MAP_DEFAULT_ZOOM,
      },
      {
        id: "Riport",
        label: "Riport",
        icon: <BackupTableIcon />,
        route: "/dashboard/reports",
      },
    ],
  },

  {
    headerId: "Vezérlőpult",
    children: [
      {
        id: "RFID azonosítók",
        label: "RFID azonosítók",
        icon: <CreditScoreIcon />,
        route: "/dashboard/chargetokens",
      },
      {
        id: "Felhasználók",
        label: "Felhasználók",
        icon: <PersonIcon />,
        route: "/dashboard/users",
      },
      {
        id: "Profilok",
        label: "Profilok",
        icon: <GroupIcon />,
        route: "/dashboard/profiles",
      },
    ],
  },
];

export default dashboardMenu;
