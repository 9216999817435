import * as React from "react";

import Box from "@mui/material/Box";
import EvStationIcon from "@mui/icons-material/EvStation";

/***************************************************************************/

interface Props {
  stationState: string;
  station: Station;
  lat: number;
  lng: number;
  text: string;
  group: string;
}

/***************************************************************************/

export default function StationMarker(props: Props) {
  const stationColor =
    props.stationState === "charging"
      ? "#412ee8"
      : props.stationState === "idle"
      ? "#d6ac11"
      : props.stationState === "offline"
      ? "#d40b0b"
      : "grey";

  return (
    <React.Fragment>
      <EvStationIcon style={{ fontSize: "50", color: stationColor }} />
    </React.Fragment>
  );
}
