import * as React from "react";
import {
  DataGrid,
  GridColDef,
  gridPageSelector,
  GridToolbarContainer,
  gridPageCountSelector,
  GridToolbarExport,
  GridValueGetterParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Pagination,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  collection,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { User } from "firebase/auth";
import { setUpSnapshotListener } from "../app/apis/firebase/dbUtils";
import {
  stationsWithSumTransactions,
  tokensWithSumTransactions,
} from "./util-modules/reportBuilder";
import FiltersPane from "./util-components/FiltersPane";
import { ReportTypes } from "./constants/reportTypes";
import { StationsTransactionsRecordType } from "./typedefs/StationsTransactionsRecordType";
import { TokensTransactionsRecordType } from "./typedefs/TokensTransactionsRecordType";
import GeneralBarStack from "../app/charts/GeneralBarStack";
import Firebase from "../app/apis/firebase/Firebase";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import theme from "../app/theme/AppTheme";

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
}

/***************************************************************************/

export default function ReportsScreen(props: Props) {

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [radioValue, setRadioValue] = React.useState<ReportTypes>(
    ReportTypes.StationsWithTXs
  );
  const [grid, setGrid] = React.useState<{ columns: any[]; rows: any[] }>();

  const [dateFilters, setDateFilters] = React.useState<DateFilter[]>([]);

  const [
    stationsWithSumTransactionsRecords,
    setStationsWithSumTransactionsRecords,
  ] = React.useState<StationsTransactionsRecordType[] | null>(null);

  const [
    tokensWithSumTransactionsRecords,
    setTokensWithSumTransactionsRecords,
  ] = React.useState<TokensTransactionsRecordType[] | null>(null);

  const [dataGridHeight, setDataGridHeight] = React.useState<number>(
    window.innerHeight - 250
  );

  function handleResize() {
    setDataGridHeight(window.innerHeight - 250);
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const filteredTransactions = React.useMemo(() => {
    if (dateFilters.length > 0) {
      return props.transactions.filter((tx: Transaction) => {
        //console.log("resultItems before filter");
        //console.log(resultItems.length);
        let filterReturn: boolean = false;
        for (let i = 0; i < dateFilters.length; i++) {
          filterReturn =
            tx.startTime >= dateFilters[i].start &&
            parseInt(tx.values[tx.values.length - 1].timestamp) <=
              dateFilters[i].end;
          if (filterReturn) break;
        }
        return filterReturn;
      });
    } else {
      return props.transactions;
    }
  }, [
    radioValue,
    props.chargeTokens,
    props.transactions,
    props.stations,
    dateFilters,
  ]);

  const memoizedValue = React.useMemo(() => {
    switch (radioValue) {
      case ReportTypes.TokensWithTXs:
        updateGrid(tokensWithSumTransactions);
        break;
      case ReportTypes.StationsWithTXs:
        updateGrid(stationsWithSumTransactions);
        break;
    }
  }, [
    radioValue,
    props.chargeTokens,
    filteredTransactions,
    props.stations,
    dateFilters,
  ]);

  function updateGrid(reportBuilderCallback: any) {
    let columns = new Array<any>();
    let rows = new Array<any>();
    if (!props.chargeTokens || !filteredTransactions || !props.stations) {
      return;
    }

    let compoundResult = reportBuilderCallback(
      props.stations,
      filteredTransactions,
      props.chargeTokens
    );
    if (!compoundResult[0]) return;

    switch (reportBuilderCallback) {
      case tokensWithSumTransactions:
        setTokensWithSumTransactionsRecords(compoundResult);
        break;
      case stationsWithSumTransactions:
        setStationsWithSumTransactionsRecords(compoundResult);
        break;
    }

    columns = Object.entries(compoundResult[0]).map(([fieldName, value]) => {
      return {
        field: fieldName,
        headerName: fieldName,
        width: 130,
        editable: false,
      };
    });
    rows = compoundResult;
    console.log([...columns]);
    console.log("[...columns]");
    console.log([...rows]);
    console.log("[...rows]");
    setGrid({ columns, rows });
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          sx={{
            color: theme.palette.secondary.contrastText,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{margin: 1}}>
      <Stack direction="column" spacing={0}>
        <List
          sx={{
            m: 2,
            maxWidth: isSmUp? "50%": "100%" /*, maxWidth: 360*/,
            bgcolor: theme.palette.secondary.main,
            zIndex: 5,
            boxShadow: "5px",
            position: "relative",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleClick}>
            <ListItemText
              primary={
                "Szűrők: " +
                (dateFilters.length > 0
                  ? dateFilters.length
                  : "Nincs aktív szűrő")
              }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <FiltersPane
              dateFilters_passedDown={dateFilters}
              setDateFilters_passedDown={setDateFilters}
            />
          </Collapse>
        </List>

        <FormControl
          sx={{ marginTop: "0px", background: theme.palette.secondary.main }}
        >
          <ToggleButtonGroup
            sx={{
              minWidth: "100%",
              marginTop: "0px",
              background: theme.palette.secondary.main,
            }}
            //color="primary"
            value={radioValue}
            exclusive
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newValue: string
            ) => {
              setRadioValue(newValue as ReportTypes);
            }}
          >
            {Object.values(ReportTypes).map((value) => {
              return (
                <ToggleButton
                  value={value}
                  color="secondary"
                  sx={{
                    background: theme.palette.secondary.light,
                    borderRadius: 0,
                    minWidth: 100 / Object.values(ReportTypes).length + "%",
                    height: "50px",
                  }}
                >
                  <Stack direction="row" spacing={0}>
                    <Typography
                      sx={{}}
                      color={theme.palette.secondary.contrastText}
                    >
                      {value}
                    </Typography>
                  </Stack>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </FormControl>

        <div
          style={{
            height: dataGridHeight,
            width: "100%",
            marginTop: "0px",
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              color: theme.palette.secondary.contrastText,
            }}
          >
            <div style={{ flexGrow: 1 }}>
              {grid && grid.rows && grid.columns ? (
                <DataGrid
                  sx={{
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.contrastText,
                    borderRadius: 0,
                  }}
                  getRowId={(row) => row._id}
                  rows={grid.rows}
                  columns={grid.columns}
                  pageSize={100}
                  rowsPerPageOptions={[5]}
                  //checkboxSelection
                  disableSelectionOnClick
                  componentsProps={{
                    toolbar: { csvOptions: { disableToolbarButton: false } },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Pagination: CustomPagination,
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Stack>
    </Box>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      //color="secondary"
      sx={{
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        borderRadius: 0,
      }}
      count={pageCount ? Number(pageCount) : 0}
      page={page ? page + 1 : 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
