import { StationsToStatesMapType } from "../../overview/OverViewScreen";
import { StationState } from "../../stations/constants/StationState";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";

/**
 *
 * @param txs
 * @param stations
 * @returns
 */
export const createStationToStatesMap = (
  txs: Transaction[],
  stations: Station[]
): StationsToStatesMapType | null => {
  let mapObj: StationsToStatesMapType = {};
  if (!txs || !stations || txs.length === 0 || stations.length === 0) {
    console.error(
      "CreateStationToStatesMap function: txs or stations param is null or empty"
    );
    return null;
  }

  const stationsCharging = txs
    .filter((tx) => tx.ended === false)
    .map((tx) => tx.station);

  stations.forEach((station: Station) => {
    if (stationsCharging.includes(station.id)) {
      mapObj[station.id] = {
        owner: station.owner,
        hub: station.hub,
        state: StationState[StationState.charging],
      };
    } else if (Date.now() - station.lastHeartbeat < 1000 * 60) {
      mapObj[station.id] = {
        owner: station.owner,
        hub: station.hub,
        state: StationState[StationState.idle],
      };
    } else
      mapObj[station.id] = {
        owner: station.owner,
        hub: station.hub,
        state: StationState[StationState.offline],
      };
  });

  return mapObj;
};

/**
 *
 * @param stations
 * @returns
 */

export const createLocationToStationMap = (
  stations: Station[]
): LocationToStationMapType | null => {
  if (!stations || stations.length === 0) {
    console.error(
      "CreateStationToStatesMap function: stations param is null or empty"
    );
    return null;
  }

  let mapObj: LocationToStationMapType = {};

  stations.forEach((station: Station) => {
    const groupId = String((station.latitude + station.longitude).toFixed(1));
    if (!mapObj[groupId]) {
      mapObj[groupId] = new Array<Station>();
    }
    mapObj[groupId].push(station);
  });

  return mapObj;
};

/**
 *
 * @param stationsAtThisLocation
 * @returns
 */

export const calculateAvgLatitude = (
  stationsAtThisLocation: Station[]
): number => {
  if (!stationsAtThisLocation || stationsAtThisLocation.length === 0) return 0;
  const sortByLat = stationsAtThisLocation.sort(
    (a: Station, b: Station) => a.latitude - b.latitude
  );
  console.log("{...sortByLat[0].latitude}", sortByLat[0].latitude);
  const tempAvg: number =
    sortByLat[0].latitude +
    (sortByLat[sortByLat.length - 1].latitude - sortByLat[0].latitude) / 2;
  return tempAvg === 0 ? sortByLat[0].latitude : tempAvg;
};

/**
 *
 * @param stationsAtThisLocation
 * @returns
 */

export const calculateAvgLongitude = (
  stationsAtThisLocation: Station[]
): number => {
  if (!stationsAtThisLocation || stationsAtThisLocation.length === 0) return 0;

  const sortByLng = stationsAtThisLocation.sort(
    (a: Station, b: Station) => a.longitude - b.longitude
  );
  const tempAvg: number =
    sortByLng[0].longitude +
    (sortByLng[sortByLng.length - 1].longitude - sortByLng[0].longitude) / 2;
  return tempAvg === 0 ? sortByLng[0].longitude : tempAvg;
};

export const navigateToMapLocation = (
  location: Location,
  navigate: NavigateFunction,
  center: LocationCoordinates,
  zoom: number
) => {
  navigate(
    location.pathname.split("/")[0] +
      "/dashboard/map/lat/" +
      center.lat +
      "/lng/" +
      center.lng +
      "/zoom/" +
      zoom
    /*{
      replace: true,
    }*/
  );
};
