import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";

import { PermissionType } from "../typedefs/PermissionType";
import { ScopeEnum } from "../constants/ScopeEnum";
import { ExistingFirestoreCollection } from "../../users/typedefs/MTFirestore";
import { ProfileType } from "../typedefs/ProfileType";
import PermissionForm from "./PermissionForm";
import { CloudUser } from "../../users/typedefs/CloudUser";
import { profilesAreEqual } from "../../app/apis/firebase/dbUtils";
import { CurrentUserPermissiontype } from "../../app/App";

/***************************************************************************/

interface Props {
  handleUpdateUsers_passedDown: (users: CloudUser[]) => void;
  users_passedDown: CloudUser[];
  profile_passedDown: ProfileType;
  setProfile_passedDown: (profile: ProfileType) => void;
  variant: "modifyDelete" | "new";
  handleProfileModify_PassedDown: (modifiedProfile: ProfileType) => void;
  handleProfileDelete_PassedDown: (deletedProfile: ProfileType) => void;
  handleNewProfile_PassedDown: (profile: ProfileType) => void;
}

/***************************************************************************/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/***************************************************************************/

export default function ProfileForm(props: Props) {
  console.log("/// ProfileForm rendered");

  const [selectedUserIds, setselectedUserIds] = React.useState<string[]>(
    props.users_passedDown
      .filter((user) => {
        return /*user.profile === props.profile_passedDown.Id;*/ profilesAreEqual(
          user,
          props.profile_passedDown
        );
      })
      .map((user: CloudUser) => user.Id)
  );
  const [originalUserIds, setOriginalUserIds] = React.useState<string[]>(
    props.users_passedDown
      .filter((user) => {
        return /*user.profile === props.profile_passedDown.Id;*/ profilesAreEqual(
          user,
          props.profile_passedDown
        );
      })
      .map((user: CloudUser) => user.Id)
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedUserIds>) => {
    setselectedUserIds(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  function setUsers() {
    props.handleUpdateUsers_passedDown(
      props.users_passedDown.map((user: CloudUser) => {
        if (user.Id && selectedUserIds.includes(user.Id)) {
          console.log('props.profile_passedDown.permissions', props.profile_passedDown.permissions);
          return {
            ...user,
            profile: props.profile_passedDown.Id,
            permissions: props.profile_passedDown.permissions,
          };
        } else if (user.Id && originalUserIds.includes(user.Id)) {
          return {
            ...user,
            profile: "None",
            permissions: {
              company: {
                authTokens: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                stations: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                transactions: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                users: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
              },
              global: {
                authTokens: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                stations: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                transactions: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                users: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
              },
            },
          };
        } else {
          return user;
        }
      })
    );
  }

  function setUsersOnProfileDelete() {
    props.handleUpdateUsers_passedDown(
      props.users_passedDown.map((user: CloudUser) => {
        if (user.Id && user.profile === props.profile_passedDown.Id) {
          return {
            ...user,
            profile: "None",
            permissions: {
              company: {
                authTokens: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                stations: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                transactions: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                users: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
              },
              global: {
                authTokens: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                stations: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                transactions: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
                users: {
                  create: false,
                  delete: false,
                  read: false,
                  update: false,
                },
              },
            },
          };
        } else {
          return user;
        }
      })
    );
  }

  return (
    <FormControl fullWidth>
      <FormGroup row sx={{ justifyContent: "space-between", width: "100%" }}>
        {
          <Stack
            direction="column"
            spacing={3}
            sx={{ width: "100%" /*height: "100%" */ }}
          >
            {props.variant === "new" ? (
              <TextField
                id="profileIdInput"
                label="Profil Id"
                variant="outlined"
                value={props.profile_passedDown.Id}
                sx={{ width: "100%" /*height: "100%" */ }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.setProfile_passedDown({
                    ...props.profile_passedDown,
                    Id: event.target.value as string,
                  });
                }}
              />
            ) : null}
            <TextField
              id="profileNameInput"
              label="Profil Név"
              variant="outlined"
              value={props.profile_passedDown.label}
              sx={{ width: "100%" /*height: "100%" */ }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.setProfile_passedDown({
                  ...props.profile_passedDown,
                  label: event.target.value as string,
                });
              }}
            />
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedUserIds}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {props.users_passedDown.map((user: CloudUser) => {
                if (user.Id) {
                  return (
                    <MenuItem key={user.Id} value={user.Id} style={{}}>
                      {user.Id}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </Stack>
        }
        <Typography mt={3} mb={3} variant="subtitle2">Global Permissions</Typography>
        {props.profile_passedDown.permissions.global
          ? Object.entries(props.profile_passedDown.permissions.global).map(
              ([collectionName, CRUDPermissions]) => {
                return (
                  <Box sx={{ justifyContent: "space-between", width: "100%" }}>
                    <PermissionForm
                      collection_passedDown={collectionName}
                      CRUDPermissions_passedDown={CRUDPermissions}
                      setCRUDPermissions_passedDown={(
                        modifiedGlobalCRUDPermissions: any
                      ) => {
                        let tempProfile = { ...props.profile_passedDown };
                        tempProfile.permissions.global[collectionName] =
                          modifiedGlobalCRUDPermissions;
                        props.setProfile_passedDown(tempProfile);
                      }}
                    />
                    <Divider variant="middle" />
                  </Box>
                );
              }
            )
          : null}
        <Typography mt={3} mb={3} variant="subtitle2">Company Permissions</Typography>
        {props.profile_passedDown.permissions.company
          ? Object.entries(props.profile_passedDown.permissions.company).map(
              ([collectionName, CRUDPermissionsCompany]) => {
                return (
                  <Box sx={{ justifyContent: "space-between", width: "100%" }}>
                    <PermissionForm
                      collection_passedDown={collectionName}
                      CRUDPermissions_passedDown={CRUDPermissionsCompany}
                      setCRUDPermissions_passedDown={(
                        modifiedCompanyCRUDPermissions: any
                      ) => {
                        let tempProfile = { ...props.profile_passedDown };
                        tempProfile.permissions.company[collectionName] =
                        modifiedCompanyCRUDPermissions;
                        props.setProfile_passedDown(tempProfile);
                      }}
                    />
                    <Divider variant="middle" />
                  </Box>
                );
              }
            )
          : null}
        {props.variant === "modifyDelete" ? (
          <React.Fragment>
            <Button
              color="error"
              variant="contained"
              sx={{ width: "100%", height: "100%", mt: 1 }}
              onClick={() => {
                setUsersOnProfileDelete();
                props.handleProfileDelete_PassedDown(props.profile_passedDown);
              }}
            >
              Profil törlése
            </Button>
            <Button
              color="warning"
              variant="contained"
              sx={{ width: "100%", height: "100%", mt: 1 }}
              onClick={() => {
                setUsers();
                /* props.users_passedDown.forEach((element) => {
                  console.log(
                    "element.Id: " +
                      element.Id +
                      "element.profile: " +
                      element.profile
                  );
                });*/
                props.handleProfileModify_PassedDown(props.profile_passedDown);
              }}
            >
              Profil módosítása
            </Button>
          </React.Fragment>
        ) : (
          <Button
            color="success"
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() => {
              setUsers();
              props.handleNewProfile_PassedDown(props.profile_passedDown);
            }}
          >
            Új profil rögzítése
          </Button>
        )}
      </FormGroup>
    </FormControl>
  );
}
