import * as React from "react";
import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Stack from "@mui/material/Stack";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Fab,
  TextField,
  Typography,
} from "@mui/material";

import { setUpSnapshotListener } from "../app/apis/firebase/dbUtils";
import Firebase from "../app/apis/firebase/Firebase";
import { ProfileType } from "./typedefs/ProfileType";
import Profile from "./util-components/Profile";
import { PermissionType } from "./typedefs/PermissionType";
import { ExistingFirestoreCollection } from "../users/typedefs/MTFirestore";
import ProfileForm from "./util-components/ProfileForm";
import { CloudUser } from "../users/typedefs/CloudUser";

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: ProfileType[];
  handleNewProfile: (profile: ProfileType) => void;
  handleModifiedProfile: (profile: ProfileType) => void;
  handleDeletedProfile: (profile: ProfileType) => void;
  handleUpdateUsers: (user: CloudUser[]) => void;
}

/***************************************************************************/

export default function ProfilesScreen(props: Props) {
  const [newProfileTemplate, setNewProfileTemplate] = useState<ProfileType>({
    //Default values of a new profile
    Id: "operatorX",
    label: "Operátor X",
    permissions: {
      company: {
        authTokens: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
        stations: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
        transactions: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
        users: {
          create: true,
          delete: true,
          read: true,
          update: true,
        },
      },
      global: {
        authTokens: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        stations: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        transactions: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        users: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
      },
    },
    company: "",
    owner: "",
  });
  const [currentExpandedProfile, setCurrentExpandedProfile] =
    React.useState<ProfileType | null>(null);
  const [showNewProfileForm, setShowNewProfileForm] =
    React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);


  function generateDefaultPermissionsArray(): PermissionType[] {
    console.log("generatedefault ran");
    return Object.keys(ExistingFirestoreCollection).map((key) => {
      return {
        CRUDPermissions: {
          create: false,
          delete: false,
          read: false,
          update: false,
        },
        collection: key,
        scope: "company",
      };
    });
  }

  return (
    <Container>
      <Stack spacing={3} mt={5} mb={5} ml={2} mr={2}>
        {showNewProfileForm ? (
          <Stack spacing={3}>
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => setShowNewProfileForm(false)}
            >
              <RemoveIcon />
            </Fab>
            <ProfileForm
              handleUpdateUsers_passedDown={props.handleUpdateUsers}
              users_passedDown={props.users}
              profile_passedDown={newProfileTemplate}
              setProfile_passedDown={(newProfile: ProfileType) => {
                setNewProfileTemplate(newProfile);
              }}
              variant="new"
              handleProfileModify_PassedDown={(
                modifiedProfile: ProfileType
              ) => {
                props.handleModifiedProfile(modifiedProfile);
              }}
              handleProfileDelete_PassedDown={(deletedProfile: ProfileType) => {
                props.handleDeletedProfile(deletedProfile);
              }}
              handleNewProfile_PassedDown={(profile: ProfileType) => {
                props.handleNewProfile(profile);
                setShowNewProfileForm(false);
              }}
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={3} alignItems="center">
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => setShowNewProfileForm(true)}
            >
              <AddIcon />
            </Fab>
            <Typography variant="h6">Új profil hozzáadása</Typography>
          </Stack>
        )}

        <Divider />
        <Typography variant="h6">Létező profilok</Typography>
        <Stack spacing={3}>
          {props.profiles && props.profiles.length > 0 && props.users && props.users.length > 0 ? (
            props.profiles.map((profile: ProfileType) => (
              <Profile
                handleUpdateUsers_passedDown={props.handleUpdateUsers}
                users_passedDown={props.users}
                currentlyExpanded_passedDown={
                  profile === currentExpandedProfile
                }
                setCurrentlyExpanded_PassedDown={(
                  profile: ProfileType | null
                ) => {
                  setCurrentExpandedProfile(profile);
                }}
                profile_passedDown={profile}
                handleProfileModify_PassedDown={(
                  modifiedProfile: ProfileType
                ) => {
                  props.handleModifiedProfile(modifiedProfile);
                  setCurrentExpandedProfile(null);
                  setRefresh((prev) => !prev);
                }}
                handleProfileDelete_PassedDown={(
                  deletedProfile: ProfileType
                ) => {
                  props.handleDeletedProfile(deletedProfile);
                  setRefresh((prev) => !prev);
                }}
                handleNewProfile_PassedDown={(profile: ProfileType) => {
                  props.handleNewProfile(profile);
                  setRefresh((prev) => !prev);
                }}
                variant="modifyDelete"
              />
            ))
          ) : (
            <Stack
              sx={{ height: "50vh" }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="info" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
