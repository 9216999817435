export type GeneralObjectType = {
  [k: string]: any | null;
};

/**
 *
 * @param stations
 * @returns
 */
export const mapStationsToOwnersAndHubs = (
  stations: Station[]
): GeneralObjectType => {
  let result: GeneralObjectType = {};
  stations.forEach((station) => {
    if (!result[station.owner]) {
      result[station.owner] = {};
    }
    if (!result[station.owner][station.hub]) {
      result[station.owner][station.hub] = new Array<Station>();
    }
    result[station.owner][station.hub].push(station);
  });
  return result;
};

/**
 *
 * @param transactions
 * @returns
 */
export const getIdsOfStationsCharging = (transactions: Transaction[]) => {
  return transactions
    .filter((tx: Transaction) => tx.ended === false)
    .map((activeTx: Transaction) => activeTx.station);
};
