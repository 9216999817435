import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";

import LogRocket from "logrocket";
import appRoutes from "./app/routing/appRoutes";
import Firebase from "./app/apis/firebase/Firebase";
import FirebaseContext from "./app/contexts/Firebase";
import RouteContext from "./app/contexts/Route";
import { Route, Routes } from "react-router-dom";
import "./index.css";
import ScrollToTop from "./app/routing/ScrollToTop";

console.log("index file ran");

//LogRocket.init("khgzp7/mtchargerhu");

export const startTime = Date.now();

const firebase = new Firebase();

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={firebase}>
      <RouteContext.Provider value={appRoutes}>
        
        <App firebase={firebase} />
      </RouteContext.Provider>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
