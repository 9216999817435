import * as React from "react";
import { Button, Container, Divider, Grid, Paper, Stack } from "@mui/material";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import huLocale from "date-fns/locale/hu";

const localeMap = {
  hu: huLocale,
};

const maskMap = {
  hu: "__-__-____",
};

interface Props {
  defaultStart?: Date;
  defaultEnd?: Date;
  handleIntervalSelection: (start: Date, end: Date) => void;
}

export default function TimeIntervalInput(props: Props) {
  const [start, setStart] = React.useState<Date>(
    props.defaultStart ? props.defaultStart : new Date()
  );
  const [end, setEnd] = React.useState<Date>(
    props.defaultEnd ? props.defaultEnd : new Date()
  );

  //DatetimePicker
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [locale, setLocale] = React.useState<keyof typeof maskMap>("hu");

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Stack direction="row" justifyContent="space-evenly" spacing={1}  sx={{width: "100%"}}>
        
        <Stack spacing={0} sx={{width: "100%"}}>
          <DateTimePicker
            //mask={maskMap[locale]}
            renderInput={(props) => <TextField {...props} />}
            label="Kezdve"
            value={start}
            onChange={(newStart) => {
              if (newStart) {
                setStart(newStart);
                props.handleIntervalSelection(newStart, end);
              }
            }}
          />
        </Stack>
        <Stack  spacing={0} sx={{width: "100%"}}>
          <DateTimePicker
            //mask={maskMap[locale]}
            renderInput={(props) => <TextField {...props} />}
            label="Bezárólag"
            value={end}
            onChange={(newEnd) => {
              if (newEnd) {
                setEnd(newEnd);
                props.handleIntervalSelection(start, newEnd);
              }
            }}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}
