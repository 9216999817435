import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReactElement, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JsxElement } from "typescript";
import { CloudUser } from "../typedefs/CloudUser";
import UserForm from "./UserForm";
import { ProfileType } from "../../profiles/typedefs/ProfileType";

/***************************************************************************/

interface Props {
  profiles_passedDown: ProfileType[];
  user_passedDown: CloudUser;
  setUser_passedDown: (user: CloudUser) => void;
  currentlyExpanded_passedDown: boolean;
  setCurrentlyExpanded_PassedDown: (user: CloudUser | null) => void;
  //setuser_passedDown: (changedProfile: ProfileType) => void;
  variant: "modifyDelete" | "new";
  handleUserModify_PassedDown: (modifiedUser: CloudUser) => void;
  handleUserDelete_PassedDown: (deletedUser: CloudUser) => void;
  handleNewUser_PassedDown: (user: CloudUser) => void;
}

/***************************************************************************/

export default function UserItem(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [bgColor, setBgColor] = React.useState<string>("white");

  const [selectedUser, setSelectedUser] = React.useState<CloudUser>(
    props.user_passedDown
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  //  console.log("in Profile component: ");
  //console.log(Object.entries(selectedUser));

  return (
    <Box>
      <Accordion
        sx={{
          background: props.currentlyExpanded_passedDown ? "#f5f6f7" : "white",
        }}
        expanded={props.currentlyExpanded_passedDown}
        onChange={() => {
          if (props.currentlyExpanded_passedDown) {
            setExpanded(false);
            props.setCurrentlyExpanded_PassedDown(null);
          } else {
            props.setCurrentlyExpanded_PassedDown(props.user_passedDown);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            User ID: {selectedUser.Id}
          </Typography>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            User Email: {selectedUser.email}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            <UserForm
              profiles_passedDown={props.profiles_passedDown}
              user_passedDown={props.user_passedDown}
              setUser_passedDown={setSelectedUser}
              variant={props.variant}
              handleUserModify_PassedDown={props.handleUserModify_PassedDown}
              handleUserDelete_PassedDown={props.handleUserDelete_PassedDown}
              handleNewUser_PassedDown={props.handleNewUser_PassedDown}
            />
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
