import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReactElement, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JsxElement } from "typescript";
import { Button, CircularProgress, Grid } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../app/theme/AppTheme";
import { toHHMMSS } from "../../app/theme/dimensionHelpers";
import StationChargingIndicator from "../../dashboard/util-components/StationChargingIndicator";

const main = {
  bgcolor: theme.palette.secondary.main,
};
const typography = {
  color: theme.palette.secondary.contrastText,
  secondaryColor: theme.palette.secondary.dark,
};

/***************************************************************************/

interface Props {
  transaction_passedDown: Transaction;
  minHeight: number;
  inProgress: boolean;
}

/***************************************************************************/

export default function TransactionItem(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [bgColor, setBgColor] = React.useState<string>("white");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleExpandClick = () => {
    navigate(
      location.pathname.split("/")[0] +
        "/dashboard/transactions/id/" +
        props.transaction_passedDown.id
    );
  };

  const meterValuesSorted = props.transaction_passedDown.values.sort(
    (a, b) => a.timestamp - b.timestamp
  );

  React.useEffect(() => {
    setExpanded(false);
    const timeOutID = setTimeout(() => {
      setBgColor(props.transaction_passedDown.ended ? main.bgcolor : "white");
    }, 100);
    return () => clearTimeout(timeOutID);
  }, [props.transaction_passedDown]);

  return (
    <Box width="100%">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ background: bgColor, minHeight: props.minHeight }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid
            container
            spacing={0}
            m={0}
            sx={
              {
                /*height: "25px"*/
              }
            }
          >
            <Grid item xs={10} sm={10} md={4} lg={3} xl={3}>
              <Stack direction="row" spacing={2} alignContent="center">
                <Typography
                  color={typography.secondaryColor}
                  variant="subtitle1"
                >
                  Dátum
                </Typography>

                <Typography variant="subtitle1" color={typography.color}>
                  {new Date(
                    props.transaction_passedDown.startTime
                  ).toLocaleDateString("hu-HU")}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={10} sm={10} md={8} lg={3} xl={3}>
              <Stack direction="row" spacing={2}>
                <Typography
                  color={typography.secondaryColor}
                  variant="subtitle1"
                >
                  Töltés időtartama
                </Typography>
                <Typography variant="subtitle1" color={typography.color}>
                  {toHHMMSS(
                    String(
                      meterValuesSorted[meterValuesSorted.length - 1]
                        .timestamp - props.transaction_passedDown.startTime
                    )
                  )}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={10} sm={10} md={4} lg={3} xl={3}>
              <Stack direction="row" spacing={2}>
                <Typography
                  color={typography.secondaryColor}
                  variant="subtitle1"
                >
                  Töltés kezdete
                </Typography>

                <Typography variant="subtitle1" color={typography.color}>
                  {new Date(
                    props.transaction_passedDown.startTime
                  ).toLocaleTimeString("hu-HU")}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={10} sm={10} md={8} lg={3} xl={3}>
              <Stack direction="row" spacing={2}>
                <Typography
                  color={typography.secondaryColor}
                  variant="subtitle1"
                >
                  Töltés vége
                </Typography>
                <Typography variant="subtitle1" color={typography.color}>
                  {new Date(
                    meterValuesSorted[meterValuesSorted.length - 1].timestamp
                  ).toLocaleTimeString("hu-HU")}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              {props.inProgress ? (
                <StationChargingIndicator
                  sx={{
                    mr: 0,
                    mt: "0px",
                    fontSize: "45px",
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Stack direction="row" spacing={2}>
              <Typography color={typography.secondaryColor} variant="subtitle1">
                Azonosító
              </Typography>
              <Typography variant="subtitle1" color={typography.color}>
                {props.transaction_passedDown.id}
              </Typography>
            </Stack>
            <Button
              //className={onlineStatus ? classes.root : classes.active}
              variant="contained"
              onClick={handleExpandClick}
              aria-label="show more"
              color="primary"
              sx={{ height: "100%", width: "100%" }}
            >
              Töltés monitor
              <ArrowForwardIosIcon
              //className={onlineStatus ? classes.root : classes.active}
              />
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
