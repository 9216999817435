import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Token } from "typescript";
import { TokenStatus } from "../constants/TokenStatus";


interface Props {
  chargeToken_PassedDown: ChargeToken;
  setChargeToken_PassedDown: (params: any) => void;
}

export default function ChargeTokenStatusSelector(props: Props) {
  return (
      <FormControl fullWidth>
        <InputLabel id="select-label">TokenStatus</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.chargeToken_PassedDown.status}
          label="TokenStatus"
          onChange={(event: any) =>
            props.setChargeToken_PassedDown((prevSettings: any) => {
              return {
                ...prevSettings,
                status: event.target.value as TokenStatus,
              };
            })
          }
        >
          {Object.values(TokenStatus)
            .filter((x) => typeof x === "string")
            .map((value: TokenStatus) => (
              <MenuItem value={value}>{TokenStatus[value]}</MenuItem>
            ))}
        </Select>
      </FormControl>
  );
}
