import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import VisxAreaChart from "./VisxAreaChart";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import theme from "../../app/theme/AppTheme";
import { px, vw } from "../../app/theme/dimensionHelpers";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "5px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

interface Props {
  freezeTime_PassedDown: boolean;
}

export default function MeterValueCard(props: any) {
  //const isSmDown = useMediaQuery(theme.breakpoints.down("xs"));
  const lessThanMediumScreen = useMediaQuery("(max-width:899px)");
  const lessThanSmallScreen = useMediaQuery("(max-width:599px)");
  const height_px = 350;
  const width_mobile_vw = 90;
  const width_tablet_vw = 40;
  const width_desktop_px = 450;

  return (
    <Card
      elevation={5}
      sx={{
        width: lessThanMediumScreen
          ? lessThanSmallScreen
            ? vw(width_mobile_vw)
            : vw(width_tablet_vw)
          : px(width_desktop_px),
        maxWidth: lessThanMediumScreen
          ? lessThanSmallScreen
            ? vw(width_mobile_vw)
            : vw(width_tablet_vw)
          : px(width_desktop_px),
        minWidth: "0px",
        height: px(height_px),
        m: "0px",
        background: theme.palette.secondary.main,
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              direction="column"
              justifyContent="end"
              alignItems="end"
              spacing={0}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {props.label}
              </Typography>
              <Typography variant="h5" component="div">
                {props.measured} {props.units}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {props.subscript}
              </Typography>
            </Stack>
            {props.freezeTime_PassedDown ? (
              <Typography sx={{ mb: 0 }} color="text.secondary"></Typography>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                /*m: 0,
              maxHeight: 500,
              maxWidth: 500,
            */
              }
            }
          >
            <Box
              sx={{
                m: 0,

                height: "70%",
                width: "100%",
                minHeight: px(height_px - 150),
                minWidth: "50px",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              {props.chart}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
