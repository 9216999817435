import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles, styled } from "@mui/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SetChargeParameters from "./ChargeParametersForm";
import LockIcon from "@mui/icons-material/Lock";
import Lock from "@mui/icons-material/Lock";
import { DateTimePicker } from "@mui/lab";
import SetLock from "./LockIntervalSelector";
import { StationState } from "../constants/StationState";
import { ChargeMode } from "../constants/ChargeMode";
import { setUpSnapshotListener } from "../../app/apis/firebase/dbUtils";
import {
  collection,
  Firestore,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { User } from "firebase/auth";
import Firebase from "../../app/apis/firebase/Firebase";
import { CloudUser, Profile } from "../../users/typedefs/CloudUser";
import theme from "../../app/theme/AppTheme";
import StationChargingIndicator from "../../dashboard/util-components/StationChargingIndicator";
import StationIdleIndicator from "../../dashboard/util-components/StationIdleIndicator";
import StationOfflineIndicator from "../../dashboard/util-components/StationOfflineIndicator";

//import { clearInterval } from "timers";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.light,
    margin: 2,
    /*color: theme.palette.secondary.main,*/
  },
  active: {
    backgroundColor: theme.palette.info.light,
    margin: 2,
    /*color: theme.palette.secondary.main,*/
  },
}));

const main = {
  bgcolor: theme.palette.secondary.light,
};
const typography = {
  color: theme.palette.secondary.contrastText,
  secondaryColor: theme.palette.secondary.dark,
};

const controlButtonWidth = "150px";

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
  //theme: any;
  station: any;
  //item: any;
}

/***************************************************************************/

export default function StationItem(props: Props) {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const location = useLocation();

  //const classes = useStyles(props.theme);

  const handleExpandClick = () => {
    navigate(
      location.pathname.split("/")[0] +
        "/dashboard/transactions/station/" +
        props.station.id
    );
    //window.scrollTo(0, 0);
  };

  const [flashing, setFlashing] = useState(false);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  const [currentSelectedState, setCurrentSelectedState] =
    useState<StationState>(StationState.idle);

  const [stationLock, setStationLock] = useState<StationLock>({
    remote_locked: false,
    local_locked: false,
  });

  const [stationLockSelected, setStationLockSelected] = useState<StationLock>({
    remote_locked: false,
    local_locked: false,
  });

  const handleLocalLock = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStationLock((prevState) => ({
      ...prevState,
      local_locked: event.target.checked,
    }));
  };

  const handleRemoteLock = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStationLock((prevState) => ({
      ...prevState,
      remote_locked: event.target.checked,
    }));
  };

  const [userInfo, setUserInfo] = React.useState<User | null>(null);
  const [db, setDb] = React.useState<Firestore | null>(null);

  const activeTransaction: Transaction | null = props.transactions[0];

  const stationTransactions = React.useMemo(() => {
    return props.transactions.filter(
      (transaction: Transaction) => transaction.station === props.station.id
    );
  }, [props.transactions, props.station]);

  const currentState: StationState = React.useMemo(() => {
    if (stationTransactions[0] && stationTransactions[0].ended === false) {
      return StationState.charging;
    } else if (Date.now() - props.station.lastHeartbeat < 1000 * 60) {
      return StationState.idle;
    } else {
      return StationState.offline;
    }
  }, [props.transactions, props.station]);

  console.log(
    "currentState === StationState.charging",
    currentState === StationState.charging
  );
  console.log(
    "currentState === StationState.idle",
    currentState === StationState.idle
  );
  console.log(
    "currentState === StationState.offline",
    currentState === StationState.offline
  );

  return (
    <Box sx={{ height: "100%", marginRight: "5px" }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          ...main,
          margin: 0,
          height: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              minWidth: "100%",
              minHeight: "40px",
              //maxHeight: "40px",
              p: 1,
            }}
          >
            <Grid container>
              <Grid item xs={10} sm={6} md={6} lg={3} xl={3}>
                <Stack
                  spacing={0}
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <Typography color={typography.color} variant="h6">
                    {props.station.id}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={10} sm={6} md={6} lg={3} xl={3}></Grid>

              <Grid item xs={10} sm={12} md={6} lg={3} xl={3}>
                <Typography
                  color={typography.secondaryColor}
                  variant="subtitle1"
                >
                  Legutóbbi bekapcsolás
                </Typography>
                <Typography variant="subtitle1" color={typography.color}>
                  {new Date(props.station.lastBoot).toLocaleDateString("hu-HU")}
                </Typography>
                <Typography variant="subtitle1" color={typography.color}>
                  {new Date(props.station.lastBoot).toLocaleTimeString("hu-HU")}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={12} md={6} lg={3} xl={3}>
                <Typography
                  color={typography.secondaryColor}
                  variant="subtitle1"
                >
                  Legutóbbi életjel
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={typography.color}
                  component="div"
                >
                  {new Date(props.station.lastHeartbeat).toLocaleDateString(
                    "hu-HU"
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={typography.color}
                  component="div"
                >
                  {new Date(props.station.lastHeartbeat).toLocaleTimeString(
                    "hu-HU"
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Stack
              direction={isSmUp ? "row" : "column"}
              justifyContent="start"
              alignItems="center"
              sx={{ minWidth: "150px", maxWidth: "180px" }}
              spacing={2}
            >
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  {stationLock.local_locked ? <LockIcon color="info" /> : null}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  {stationLock.remote_locked ? (
                    <LockIcon color="warning" />
                  ) : null}
                </Grid>
              </Grid>

              <Box>
                {currentState === StationState.charging ? (
                  <StationChargingIndicator
                    sx={{
                      mr: 0,
                      mt: "0px",
                      fontSize: "45px",
                    }}
                  />
                ) : null}

                {currentState === StationState.idle ? (
                  <StationIdleIndicator
                    sx={{
                      mr: 0,
                      ml: "22px",
                      mt: "0px",
                      fontSize: "45px",
                    }}
                  />
                ) : null}
                {currentState === StationState.offline ? (
                  <StationOfflineIndicator
                    sx={{
                      mr: 0,
                      ml: "22px",
                      mt: "0px",
                      fontSize: "45px",
                    }}
                  />
                ) : null}
              </Box>
              <Typography
                color={typography.color}
                sx={{ minWidth: "65px" }}
                variant="subtitle1"
              >
                {StationState[currentState]}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" justifyContent="space-between">
            <Stack spacing={3} direction="row" justifyContent="space-between">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Button
                    //className={onlineStatus ? classes.root : classes.active}
                    variant="contained"
                    onClick={handleExpandClick}
                    aria-label="show more"
                    //color={theme.palette.primary.light}
                    sx={{ height: "100%", width: "100%", bgcolor: theme.palette.primary.light }}
                  >
                    Töltési adatok
                    <ArrowForwardIosIcon
                    //className={onlineStatus ? classes.root : classes.active}
                    />
                  </Button>
                </Grid>
                {currentState === StationState.charging ? (
                  <Grid item xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}>
                    <Button
                      disabled
                      variant="contained"
                      color="error"
                      sx={{ height: "100%", width: controlButtonWidth }}
                      onClick={() => {
                        //setCurrentState(StationState.idle);
                        //setCurrentState(StationState)
                        //setCurrentState()setCurrentSelectedState(StationState.idle);
                      }}
                    >
                      Stop charge
                    </Button>
                  </Grid>
                ) : null}
                {currentState === StationState.idle /*&& false*/ ? (
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <Button
                      disabled
                      variant="outlined"
                      color="success"
                      sx={{ height: "100%", width: controlButtonWidth }}
                      onClick={() => {
                        //setCurrentState(StationState.charging);
                        //setCurrentSelectedState(StationState.charging);
                      }}
                    >
                      Set charge
                    </Button>
                  </Grid>
                ) : null}
                {currentState === StationState.idle /*&& false*/ ? (
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <Button
                      disabled
                      variant="outlined"
                      color="success"
                      sx={{ height: "100%", width: controlButtonWidth }}
                      onClick={() => {
                        //setCurrentState(StationState.charging);
                        //setCurrentSelectedState(StationState.charging);
                      }}
                    >
                      Set charge
                    </Button>
                  </Grid>
                ) : null}
                {currentState === StationState.idle /*&& false*/ ? (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button
                      disabled
                      variant="outlined"
                      color="error"
                      sx={{ height: "100%", width: controlButtonWidth }}
                      onClick={() => {
                        //setCurrentState(StationState.charging);
                        //setCurrentSelectedState(StationState.offline);
                        //setCurrentState(StationState.offline);
                      }}
                    >
                      Shutdown
                    </Button>
                  </Grid>
                ) : null}
                

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  {
                    /*false*/ true ? (
                      <FormGroup>
                        <Stack direction={isSmUp ? "row" : "column"}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={stationLock.local_locked}
                                onChange={handleLocalLock}
                                inputProps={{ "aria-label": "controlled" }}
                                color="info"
                              />
                            }
                            labelPlacement="start"
                            label="Helyi Zárolás"
                          />
                          <FormControlLabel
                            /*disabled*/ control={
                              <Switch
                                checked={stationLock.remote_locked}
                                onChange={handleRemoteLock}
                                inputProps={{ "aria-label": "controlled" }}
                                color="warning"
                              />
                            }
                            labelPlacement="start"
                            label="Távoli Zárolás"
                          />
                        </Stack>
                      </FormGroup>
                    ) : null
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  {currentState === StationState.offline ? (
                    <Button
                      disabled
                      variant="outlined"
                      color="info"
                      sx={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        //setCurrentState(StationState.charging);
                        //setCurrentSelectedState(StationState.idle);
                        //setCurrentState(StationState.idle);
                      }}
                    >
                      Boot Up
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Stack>
            {
              /*currentSelectedState === StationState.charging &&
          currentState !== StationState.charging
          */ currentState === StationState.idle ? (
                <SetChargeParameters
                  defaultSettings={{
                    mode: ChargeMode[ChargeMode.full],
                    power: 120,
                    requiredEnergy: 100,
                  }}
                  submitButtonColor="success"
                  submitButtonVariant="contained"
                  handleSubmit={(settings: ChargeSettings) => {
                    //setCurrentState(currentSelectedState);
                    //setCurrentSelectedState(currentSelectedState);
                  }}
                  submitButtonLabel="Start Charge"
                />
              ) : null
            }
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
