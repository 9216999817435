import sumUpMeterValues from "../../transactions/util-modules/statistics";
import { StationsTransactionsRecordType } from "../typedefs/StationsTransactionsRecordType";
import { TokensTransactionsRecordType } from "../typedefs/TokensTransactionsRecordType.d";

export function tokensWithSumTransactions(
  stations: Station[],
  transactions: Transaction[],
  tokens: ChargeToken[]
): TokensTransactionsRecordType[] | null {
  type myObjType = {
    [k: string]: any | null;
  };
  let myObj: myObjType = {};
  if(!transactions || !stations) return null;

  transactions.forEach((tx: Transaction) => {
    if (!myObj[tx.idToken.idToken]) {
      myObj[tx.idToken.idToken] = {
        tokenName: "N/A",
        txs: new Array<Transaction>(),
      };
    }
    myObj[tx.idToken.idToken].txs.push(tx);
  });

  tokens.forEach((token: ChargeToken) => {
    if (!myObj[token.token]) {
      myObj[token.token] = { tokenName: "N/A", txs: new Array<Transaction>() };
    }
    myObj[token.token]["tokenName"] = token.name;
    console.log("myObj[token.token].tokenName = token.name");
    console.log(token.name);
  });

  return Object.entries(myObj).map(([tokenId, { tokenName, txs }]) => {
    let calculatedValues = sumUpMeterValues(txs as Transaction[] | []);
    console.log("txs.length")
    console.log(txs.length)
    return {
      _id: tokenId,
      tokenHolderName: tokenName,
      numberOfTXs: txs.length,
      energyTotal: calculatedValues.energyTotal,
      currentAvg: calculatedValues.currentAvg,
      powerAvg: calculatedValues.powerAvg,
      voltageAvg: calculatedValues.voltageAvg,
    };
  });
}

type CustomTempObjType = {
  stationName: string;
  owner: string;
  hub: string;
  txs: Transaction[];
};

export function stationsWithSumTransactions(
  stations: Station[],
  transactions: Transaction[],
  tokens: ChargeToken[]
): StationsTransactionsRecordType[] | null{
  type myObjType = {
    [k: string]: CustomTempObjType;
  };
  let myObj: myObjType = {};

if(!transactions || !stations) return null;
  transactions.forEach((tx: Transaction) => {
    if (!myObj[tx.station]) {
      myObj[tx.station] = {
        stationName: "N/A",
        owner: "bla",
        hub: "N/A",
        txs: new Array<Transaction>(),
      };
    }
    myObj[tx.station].txs.push(tx);
  });

  stations.forEach((station: Station) => {
    if (!myObj[station.id]) {
      myObj[station.id] = {
        stationName: "N/A",
        owner: "N/A",
        hub: "N/A",
        txs: new Array<Transaction>(),
      };
    }
    myObj[station.id]["stationName"] = station.id;
    myObj[station.id]["owner"] = station.owner;
    myObj[station.id]["hub"] = station.hub;
  });

  return Object.entries(myObj).map(
    ([stationId, { stationName, owner, hub, txs }]: [
      string,
      { stationName: string; owner: string; hub: string; txs: Transaction[] }
    ]) => {
      let calculatedValues = sumUpMeterValues(txs as Transaction[] | []);
      return {
        _id: stationId,
        stationName: stationName,
        ownerName: owner,
        hubName: hub,
        numberOfTXs: txs.length,
        energyTotal: calculatedValues.energyTotal,
        currentAvg: calculatedValues.currentAvg,
        powerAvg: calculatedValues.powerAvg,
        voltageAvg: calculatedValues.voltageAvg,
      };
    }
  );
}
