import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import theme from "../../app/theme/AppTheme";

/***************************************************************************/

const mainBoxStyle = {
  //background: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  textColor: theme.palette.primary.contrastText,
  boxShadow: 2,
};

const tabsStyle = {
  background: theme.palette.primary.light,
  //color: theme.palette.primary.contrastText,
  boxShadow: 2,

};

const tabStyle = { color: theme.palette.secondary.main };

function a11yProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/***************************************************************************/
interface Props {
  currentTab: JSX.Element;
  tabs: any;
  routeMap: any;
}
/***************************************************************************/

export default function TabBar(props: Props) {
  //props.titleChangeHandler(props.label);

  console.log("function TabBar" + " called");

  const mapPathSplit = props.tabs.split("/");

  function fgds() {
    let resultObj = props.routeMap;
    console.log("dashboard label " + props.routeMap.dashboard.label);
    console.log("mapPathSplit.length  " + mapPathSplit.length);
    console.log(
      "props.menuMap[mapPathSplit[0]  " + props.routeMap[mapPathSplit[0]].label
    );

    for (let i = 0; i < mapPathSplit.length; i++) {
      resultObj = resultObj[mapPathSplit[i]].subRoutes;
    }
    console.log("resultObj.label " + Object.entries(resultObj));
    return resultObj;
  }
  const tabs = fgds();

  const location = useLocation();
  const currentSubPath =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const [subPath, setSubPath] = React.useState(currentSubPath);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newSubPath: string) => {
    setSubPath(newSubPath);
    navigate(location.pathname.replace(currentSubPath, newSubPath));
  };

  return (
    <Box
      sx={{
        ...mainBoxStyle,
        maxHeight: "50px",
        minHeight: "0px",
      }}
    >
      <Tabs
        sx={{
          ...tabsStyle,
          maxHeight: "50px",
          minHeight: "0px",
          position: "fixed",
          zIndex: 1,
          width: "100%",
        }}
        value={subPath}
        onChange={handleChange}
        textColor="inherit"
        //color={theme.palette.secondary.contrastText}
        indicatorColor="secondary"
        aria-label="TabBar"
      >
        {Object.entries(tabs).map(([route, { id, label }]: any) => {
          console.log("routes " + route + " " + label);
          return (
            <Tab
              value={route}
              label={label}
              //color={theme.palette.primary.contrastText}
              sx={{ ...tabStyle }}
              {...a11yProps(label)}
            />
          );
        })}
      </Tabs>
      <Box sx={{ height: "100%", paddingTop: "60px" }}>
        {props.currentTab}
      </Box>
    </Box>
  );
}
