import * as React from "react";
import { useState } from "react";

import { User } from "firebase/auth";
import {
  collection,
  Firestore,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  TextField,
} from "@mui/material";

import ChargeToken from "./util-components/Item";
import NewTokenForm from "./util-components/NewTokenForm";
import TokenModifyDeleteForm from "./util-components/ModifyOrDeleteForm";
import Firebase from "../app/apis/firebase/Firebase";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";

/***************************************************************************/

interface Props {
  firebase: Firebase;
  stations: Station[];
  transactions: Transaction[];
  chargeTokens: ChargeToken[];
  users: CloudUser[];
  profiles: Profile[];
  handleNewToken: (token: ChargeToken) => void;
  handleModifiedToken: (token: ChargeToken) => void;
  handleDeletedToken: (token: ChargeToken) => void;
}

/***************************************************************************/

export default function ChargeTokenScreen(props: Props) {
 
  return (
    <Container maxWidth={"xl"}>
      <Stack spacing={3}>
        <NewTokenForm
          buttonText="Új token rögzítése"
          buttonColor="success"
          handleNewToken_PassedDown={(newToken: ChargeToken) => {
            props.handleNewToken(newToken);
          }}
        />
        <Divider />
        <Stack spacing={3}>
          {props.chargeTokens && props.chargeTokens.length > 0 ? (
            props.chargeTokens.map((token: any) => (
              <ChargeToken
                token={token}
                dropdownContent={
                  <TokenModifyDeleteForm
                    chargeToken_PassedDown={token}
                    modifyButtonColor="warning"
                    modifyButtonText="Státusz módosítása"
                    deleteButtonColor="error"
                    deleteButtonText="Token törlése"
                    handleTokenModify_PassedDown={(
                      modifiedToken: ChargeToken
                    ) => {
                      props.handleModifiedToken(modifiedToken);
                    }}
                    handleTokenDelete_PassedDown={(
                      deletedToken: ChargeToken
                    ) => {
                      props.handleDeletedToken(deletedToken);
                    }}
                  />
                }
              />
            ))
          ) : (
            <Stack
              sx={{ height: "50vh" }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="info" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
