import * as React from "react";
import DashboardScreen from "../../dashboard/DashboardScreen";
import Login from "../../login/Login";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import UsersScreen from "../../users/UsersScreen";
import StationsScheduler from "../../timeplanner/SchedulerScreen";
import ChargeTokens from "../../chargetokens/ChargeTokenScreen";
import { JsxElement } from "typescript";
import StationsScreen from "../../stations/StationsScreen";
import dashboardMenu from "../../dashboard/constants/dashboardMenu";
import appTheme from "../theme/AppTheme";
import { withSelectedMenuItemContext } from "../contexts/SelectedMenuItem";
import TransactionsScreen from "../../transactions/TransactionsScreen";
import { withFirebaseContext } from "../contexts/Firebase";
import { withRouteContext } from "../contexts/Route";
import TabBar from "../../dashboard/util-components/TabBar";
import MeterValuesScreen from "../../transactions/util-components/MeterValuesScreen";
import ProfilesScreen from "../../profiles/ProfilesScreen";
import ReportsScreen from "../../reports/ReportsScreen";
import OverViewScreen from "../../overview/OverViewScreen";
import { withFirebaseDataContext } from "../contexts/FirebaseData";
import StationMap from "../../map/StationMap";

const appRoutes = {
  "/": {
    label: "Bejelentkezés",
    component: withFirebaseDataContext(Login, {}),
    subRoutes: {},
  },
  "login": {
    label: "Bejelentkezés",
    component: withFirebaseDataContext(Login, {}),
    subRoutes: {},
  },
  "dashboard": {
    label: "Dashboard",
    component: withSelectedMenuItemContext(DashboardScreen, {
      menu: dashboardMenu,
      theme: appTheme,
    }),
    subRoutes: {
      "stations": {
        label: "Töltő lista",
        component: React.Fragment,
        subRoutes: {
          "list": {
            label: "Töltő lista",
            component: withRouteContext(TabBar, {
              tabs: "dashboard/stations",
              currentTab: withFirebaseDataContext(StationsScreen, {}),
            }),
            subRoutes: {},
          },
          "scheduler": {
            label: "Tervezés",
            component: withRouteContext(TabBar, {
              tabs: "dashboard/stations",
              currentTab: withFirebaseDataContext(StationsScheduler, {}),
            }),
            subRoutes: {},
          },
        },
      },
      "map/lat/:lat/lng/:lng/zoom/:zoom": {
        label: "Térkép",
        component: withFirebaseDataContext(StationMap, {}),
        subRoutes: {},
      },
      "chargetokens": {
        label: "RFID azonosítók",
        component: withFirebaseDataContext(ChargeTokens, {}),
        subRoutes: {},
      },
      "users": {
        label: "Felhasználók",
        component: withFirebaseDataContext(UsersScreen, {}),
        subRoutes: {},
      },
      "profiles": {
        label: "Profilok",
        component: withFirebaseDataContext(ProfilesScreen, {}),
        subRoutes: {},
      },
      "reports": {
        label: "Reports",
        component: withFirebaseDataContext(ReportsScreen, {}),
        subRoutes: {},
      },
      "overview": {
        label: "Overview",
        component: withFirebaseDataContext(OverViewScreen, {}),
        subRoutes: {},
      },
      "transactions": {
        label: "Töltések",
        component: UsersScreen,
        subRoutes: {
          "station": {
            label: "Állomás",
            component: UsersScreen,
            subRoutes: {
              ":stationId": {
                label: "Egyedi állomás",
                component: withFirebaseDataContext(TransactionsScreen, {}),
                subRoutes: {},
              },
            },
          },
          "id": {
            label: "Tranzakció",
            component: UsersScreen,
            subRoutes: {
              ":transactionId": {
                label: "Egyedi töltés",
                component: withFirebaseDataContext(MeterValuesScreen, {}),
                subRoutes: {},
              },
            },
          },
        },
      },
    },
  },
};

export default appRoutes;
